import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';

function AddWellModal({ fieldId, fieldName, onSubmit, onClose }) {
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables

    const [wellName, setWellName] = useState("");
    const [saving, setSaving] = useState(false); // State to track saving process

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSaving(true); // Set saving state to true while request is in progress
        try {
            // Make HTTP POST request to save the new well name with authorization headers
            await axios.post(
                `${baseURL}/input/wells`,
                {
                    field_id: fieldId, // Use the fieldId prop passed from parent component
                    well_name: wellName
                },
                {
                    withCredentials: true
                }
            )

            // Call onSubmit prop with the saved field name
            console.log("Before resetting well name:", wellName);

            onSubmit(wellName);
            setWellName(""); // Reset the well name after submission
            console.log("After resetting well name: ", wellName);

            alert(`${wellName} successfully added to ${fieldName}`)
        } catch (error) {
            console.error('Error saving well name: ', error);

            alert(`${wellName} was not added!`)
        } finally {
            setSaving(false); // Reset saving state after request is completed
            onClose(); // Close the modal
        }
    };

    return (
        <Modal show={true} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add a new well for {fieldName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Label>Well Name</Form.Label>
                    <Form.Control size="lg"
                        type="text"
                        placeholder="Enter the name of the new well"
                        value={wellName}
                        onChange={(e) => setWellName(e.target.value)}
                    />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose} disabled={saving}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit} disabled={saving}>
                    {saving ? 'Saving...' : 'Save'} {/* Show different text based on saving state */}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddWellModal;
