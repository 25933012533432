import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function SignupConfirmation() {
    const location = useLocation();
    const [message, setMessage] = useState('');

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const messageParam = queryParams.get('message');

        if (messageParam === 'invalid_or_expired') {
            setMessage('The confirmation link is invalid or has expired. Please try again.');
        } else if (messageParam === 'already_confirmed') {
            setMessage('Account already confirmed. Please login.');
        } else if (messageParam === 'confirmed') {
            setMessage('You have confirmed your account. Thanks! Please log into your account.');
        }
    }, [location.search]);

    return (
        <div>
            <div className="container h-50">
                <div className="row h-50">
                    <div className="col-12">
                        <p>{message}</p>
                        <Button as={Link} to="/login">Login</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
