import { Menu } from 'react-admin';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';
// import { Link } from 'react-router-dom';

const CustomMenu = (props) => (
    <Menu {...props}>
        <Menu.Item to="/admin" primaryText="Dashboard" leftIcon={<DashboardIcon />} />
        <Menu.Item to="/admin/users" primaryText="Users" leftIcon={<PeopleIcon />} />
        <Menu.Item to="/admin/companies" primaryText="Companies" leftIcon={<SettingsIcon />} />
        <Menu.Item to="/admin/blocks" primaryText="Blocks" leftIcon={<SettingsIcon />} />
        <Menu.Item to="/admin/fields" primaryText="Fields" leftIcon={<SettingsIcon />} />
        <Menu.Item to="/admin/wells" primaryText="Wells" leftIcon={<SettingsIcon />} />
        <Menu.Item to="/admin/wellbores" primaryText="Well bores" leftIcon={<SettingsIcon />} />

        <Menu.Item to="/admin/well_design_cases" primaryText="Well Designs" leftIcon={<SettingsIcon />} />
        <Menu.Item to="/" primaryText="Home" leftIcon={<HomeIcon />} />
        <Menu.Item to="/profile" primaryText="My Profile" leftIcon={<SettingsIcon />} />
        {/* Add more links as needed */}
    </Menu>
);

export default CustomMenu;
