import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';

function AddFieldModal({ blockId, blockName, onSubmit, onClose }) {
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables

    const [fieldName, setFieldName] = useState("");
    const [saving, setSaving] = useState(false); // State to track saving process

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSaving(true); // Set saving state to true while request is in progress
        try {
            // Make HTTP POST request to save the new field name with authorization headers
            await axios.post(
                `${baseURL}/input/fields`,
                {
                    block_id: blockId, // Use the blockId prop passed from parent component
                    field_name: fieldName
                },
                {
                    withCredentials: true
                }
            );

            // Call onSubmit prop with the saved field name
            console.log("Before resetting field name:", fieldName);

            onSubmit(fieldName);
            setFieldName(""); // Reset the field name after submission
            console.log("After resetting field name: ", fieldName);

            alert(`${fieldName} successfully added to ${blockName}`)
        } catch (error) {
            console.error('Error saving field name: ', error);

            alert(`${fieldName} was not added!`)
        } finally {
            setSaving(false); // Reset saving state after request is completed
            onClose(); // Close the modal
        }
    };

    return (
        <Modal show={true} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add a new field for {blockName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Label>Field Name</Form.Label>
                    <Form.Control size="lg"
                        type="text"
                        placeholder="Enter the name of the new field"
                        value={fieldName}
                        onChange={(e) => setFieldName(e.target.value)}
                    />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose} disabled={saving}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit} disabled={saving}>
                    {saving ? 'Saving...' : 'Save'} {/* Show different text based on saving state */}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddFieldModal;
