import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
// import ExampleCarouselImage from 'components/ExampleCarouselImage';
// import webPageImage from '../images/web_page.png'; // Import the image

// Import images
// import image1 from '../images/web_page.png';
import image4 from '../images/ecd_surge.png';
import image2 from '../images/trajectory_plan_view.png';
// import image3 from '../images/trajectory_vs_view.png';
import image1 from '../images/casing_design_burst_surface.png';


function LandingCarousel() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    return (
            <Carousel activeIndex={index} onSelect={handleSelect}>
                <Carousel.Item interval={10000}>
                    {/* <ExampleCarouselImage text="First slide" /> */}
                    <img src={image1} className="img-fluid my-0" style={{ width: '100%', height: 'auto', }} alt="Drilling hydraulics" />

                    <Carousel.Caption>
                        <h3>Drilling hydraulics</h3>
                    <p>Software for hyraulics, and swab and surge simulations.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={10000}>
                    {/* <ExampleCarouselImage text="Second slide" /> */}
                    <img src={image2} className="img-fluid my-0" style={{ width: '100%', height: 'auto', }} alt="Kick tolerance" />
                    <Carousel.Caption>
                        <h3>Kick tolerance</h3>
                    <p>Perform kick tolerance with a few click.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={10000}>
                    {/* <ExampleCarouselImage text="Third slide" /> */}
                    <img src={image4} className="img-fluid my-0" style={{ width: '100%', height: 'auto', }} alt="Casing Design" />
                    <Carousel.Caption>
                        <h3>Casing design</h3>
                    <p>
                        Perform casign design.
                    </p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        
    );
}

export default LandingCarousel;