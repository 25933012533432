import React, { } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

// import { Link } from 'react-router-dom';

export default function PricingPage() {

    return (
        <div>
            <div className="container d-flex flex-column min-vh-100">
                <div className="row h-100">
                    <div className="col-12">
                        <h1>Pricing Page</h1>
                        <Row className="mb-5 mt-5">
                            <Col>
                                <Card>
                                    {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                                    <Card.Body>
                                        <Card.Title>Basic</Card.Title>
                                        <Card.Text>
                                            <p><strong>$55/month</strong></p>
                                            Access any two modules in addition to the earth model.<br />
                                            Access look up tables.
                                        </Card.Text>
                                        <Link to="/" className="btn btn-primary">Get started free</Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Elite</Card.Title>
                                        <Card.Text>
                                            <p><strong>$75/month</strong></p>
                                            Access any four modules in addition to the earth model and well trajectory planning.<br />
                                            Access look up tables and tools and calculators.
                                        </Card.Text>
                                        <Link to="/design_case" className="btn btn-primary">Get started free</Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Pro</Card.Title>
                                        <Card.Text>
                                            <p><strong>$105/month</strong></p>
                                            Access all five modules in addition to the earth model and well trajectory planning.<br />
                                            Access look up tables and tools and calculators.
                                        </Card.Text>
                                        <Link to="/login" className="btn btn-primary">Get started free</Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        
                    </div>
                </div>
            </div>
        </div>
    );
}