import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import axios from 'axios';

function EditBitModal({ show, handleClose, bit, onBitUpdated }) {
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables

    const [formData, setFormData] = useState({
        nozzle_size1: bit.nozzle_size1,
        no_of_nozzles1: bit.no_of_nozzles1,
        nozzle_size2: bit.nozzle_size2,
        no_of_nozzles2: bit.no_of_nozzles2
    });

    useEffect(() => {
        if (bit) {
            setFormData({
                nozzle_size1: bit.nozzle_size1,
                no_of_nozzles1: bit.no_of_nozzles1,
                nozzle_size2: bit.nozzle_size2,
                no_of_nozzles2: bit.no_of_nozzles2
            });
        }
    }, [bit]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const updatedBit = {
            nozzle_size1: formData.nozzle_size1,
            no_of_nozzles1: formData.no_of_nozzles1,
            nozzle_size2: formData.nozzle_size2,
            no_of_nozzles2: formData.no_of_nozzles2
        };

        axios.put(`${baseURL}/input/bit/${bit.drill_bit_id}`, updatedBit, {
            withCredentials: true
        })
            .then(response => {
                onBitUpdated(response.data.bit);
                handleClose();
            })
            .catch(error => {
                console.error('Error updating bit:', error);
            });
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Bit</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group as={Row} controlId="bitType">
                        <Form.Label column sm={4}>Bit Type</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="text"
                                value={bit.bit_type}
                                readOnly
                                disabled
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="bitIdentification">
                        <Form.Label column sm={4}>Bit Identification</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="text"
                                value={bit.bit_identification}
                                readOnly
                                disabled
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="bitSize">
                        <Form.Label column sm={4}>Bit Size</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                value={bit.bit_size}
                                readOnly
                                disabled
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="iadcCode">
                        <Form.Label column sm={4}>IADC Code</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="text"
                                value={bit.iadc_code}
                                readOnly
                                disabled
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="nozzleSize1">
                        <Form.Label column sm={4}>Jet Size 1</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                value={formData.nozzle_size1}
                                onChange={(e) => setFormData({ ...formData, nozzle_size1: e.target.value })}
                                required
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="noOfNozzles1">
                        <Form.Label column sm={4}>No of Jets 1</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                value={formData.no_of_nozzles1}
                                onChange={(e) => setFormData({ ...formData, no_of_nozzles1: e.target.value })}
                                required
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="nozzleSize2">
                        <Form.Label column sm={4}>Jet Size 2</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                value={formData.nozzle_size2}
                                onChange={(e) => setFormData({ ...formData, nozzle_size2: e.target.value })}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="noOfNozzles2">
                        <Form.Label column sm={4}>No of Jets 2</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                value={formData.no_of_nozzles2}
                                onChange={(e) => setFormData({ ...formData, no_of_nozzles2: e.target.value })}
                            />
                        </Col>
                    </Form.Group>
                    <Button variant="primary" type="submit" style={{ marginTop: '20px' }}>
                        Save Changes
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default EditBitModal;
