import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Suspense, useEffect } from 'react';
import './App.css';
import { BrowserRouter} from 'react-router-dom';
import { AuthProvider } from './components/admin/AuthContext';
import AppContent from './AppContent';
import Footer from './pages/tabs_components/Footer';
import { GlobalStateProvider } from './components/GlobalStateContext';
import { BhaDataProvider } from './components/well_information/bha_items/BhaDataProvider';
import { RheologyDataProvider } from './components/well_information/rheology/RheologyProvider';
import { CoordinateConversionProvider } from './components/trajectory/CoordinateConversionProvider';
import { SurveyDataProvider } from './components/trajectory/SurveyDataProvider';
import { HoleSectionProvider } from './components/HoleSectionProvider';
import { EarthModelProvider } from './components/design_headers/EarthModelContext';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

function App() {

  const baseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const recaptchaSiteKey = 'process.env.REACT_APP_RECAPTCHA_SITE_KEY';

    const existingScript = document.querySelector(`script[src*="https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}"]`);

    // Check if the script is already in the document
    if (!existingScript) {
      const recaptchaScript = document.createElement('script');
      recaptchaScript.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}`;
      recaptchaScript.async = true;
      recaptchaScript.defer = true;
      document.head.appendChild(recaptchaScript);
    }
  }, []);

  return (
    <GlobalStateProvider>
      <AuthProvider>
      <BrowserRouter>
          <CoordinateConversionProvider baseURL={baseURL}>
            <SurveyDataProvider baseURL={baseURL}>
              <HoleSectionProvider baseURL={baseURL}>
                <RheologyDataProvider baseURL={baseURL}>
                  <BhaDataProvider baseURL={baseURL}>
                    <EarthModelProvider>
                      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>

                      <Suspense fallback={<div>Loading...</div>}>
                        
                        <AppContent />

                        </Suspense>
                      </GoogleReCaptchaProvider>

                    </EarthModelProvider>
                  </BhaDataProvider>
                </RheologyDataProvider>
              </HoleSectionProvider>
            </SurveyDataProvider>
          </CoordinateConversionProvider>
        
        <Footer />
        </BrowserRouter>
      </AuthProvider>
    </GlobalStateProvider>
  );
}

export default App;

