import React from 'react';
import RigEquipmentTable from "./rigEquipmentTable";
import useToken from '../../admin/useToken'; // Import the custom hook

const RigEquipmentHeader = ({ selectedCompany, selectedBlock, selectedField, selectedWell, selectedWellbore, selectedWellDesignCase, wellboreId, wellId, wellDesignCaseId }) => {
    const { token, setToken } = useToken(); // Use the custom hook to manage token state

    return (
        <div className="container">

            <div className="row">
                <div className="col">
                    <RigEquipmentTable wellId={wellId} wellboreId={wellboreId} wellDesignCaseId={wellDesignCaseId} token={token} setToken={setToken} />
                    
                </div>

            </div>
        </div>
    );
};

export default RigEquipmentHeader;
