import React, { createContext, useState, useEffect } from 'react';

export const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
    const [selectedItems, setSelectedItems] = useState(() => {
        const savedState = localStorage.getItem('selectedItems');
        return savedState ? JSON.parse(savedState) : {
            company_id: '',
            company_name: '',
            block_id: '',
            block_name: '',
            field_id: '',
            field_name: '',
            well_id: '',
            well_name: '',
            wellbore_id: '',
            wellbore_name: '',
            well_type: '',
            units_id: '',
            units_type: '',
            well_design_case_id: '',
            well_design_case_name: '',
            default_bit_id: '',
            default_parameter_id: '',
            hole_default_parameter_id: '',
            rkbMsl: '',
            waterDepth: '',
            surfTemp: '',
            geothermalGrad: '',
            epsg_code: '',
            epsg_name: '',
            easting: '',
            northing: '',
            cum_rate: '',
            rheologicalParameters: {},
            nextRheologicalParameters: {},
            previousRheologicalParameters: {},
            drilling_parameters_id: '',
            drillingParameters: {},
            bitParameters: {},
            openHoleParameters: {},
            casedHoleParameters: {},
            prodCasingParameters: {},
            casing_default_parameter_id: '',
            bhaParameters: '',
            equipmentParameters: '',
            bhaItems: '',
            siteData: {},
            openHoleTvd: '',
            casedHoleTvd: '',
            swabSurgeMode: 'Surge',
            swabAndSurgeParameters: {},
            kTParameters: {},
            fsg: '',
            formationPressure: '',
            prev_casing_defaut_parameter_id: '',

        };
    });

    useEffect(() => {
        localStorage.setItem('selectedItems', JSON.stringify(selectedItems));
    }, [selectedItems]);

    return (
        <GlobalStateContext.Provider value={{ selectedItems, setSelectedItems }}>
            {children}
        </GlobalStateContext.Provider>
    );
};
