import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

function AddRheology({ show, handleClose, wellId, wellboreId, wellDesignCaseId, onRheologyAdded }) {
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables

    const [sectionTypes, setSectionTypes] = useState([]);
    const [formData, setFormData] = useState({
        section_type_id: '',
        mud_type: '',
        mwt_rheology: '',
        pv: '',
        yp: '',
        fann3: '',
        fann6: '',
        fann300: '',
        fann600: '',
        cuttings_diam: ''
    });

    useEffect(() => {
        axios.get(`${baseURL}/input/section_type`, {
            withCredentials: true
        })
            .then(response => {
                setSectionTypes(response.data);
            })
            .catch(error => {
                console.error('Error fetching section types:', error);
            });
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const dataToSend = {
            section_type_id: parseInt(formData.section_type_id),
            mud_type: formData.mud_type,
            mwt_rheology: parseFloat(formData.mwt_rheology),
            pv: parseFloat(formData.pv),
            yp: parseFloat(formData.yp),
            fann3: parseFloat(formData.fann3),
            fann6: parseFloat(formData.fann6),
            fann300: parseFloat(formData.fann300),
            fann600: parseFloat(formData.fann600),
            cuttings_diam: parseFloat(formData.cuttings_diam),
            well_id: parseInt(wellId),
            wellbore_id: parseInt(wellboreId),
            well_design_case_id: parseInt(wellDesignCaseId)
        };

        axios.post(`${baseURL}/input/rheology`, dataToSend, {
            withCredentials: true
        })
            .then(response => {
                console.log(response.data);
                onRheologyAdded();
                handleClose();
            })
            .catch(error => {
                console.error('Error creating hole section:', error);
            });
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add a New Hole Section</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group as={Row} controlId="sectionType">
                        <Form.Label column sm={4}>Section</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                as="select"
                                name="section_type_id"
                                value={formData.section_type_id}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select Section</option>
                                {sectionTypes.map(section => (
                                    <option key={section.section_type_id} value={section.section_type_id}>
                                        {section.section_type}
                                    </option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="mudType">
                        <Form.Label column sm={4}>Mud Type</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                as="select"
                                name="mud_type"
                                value={formData.mud_type}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select Mud Type</option>
                                <option value="Water Based Mud">WBM</option>
                                <option value="Oil Based Mud">OBM</option>
                                {/* <option value="SBM">SBM</option> */}
                                <option value="Brine">Brine</option>
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="mwt">
                        <Form.Label column sm={4}>Mwt</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                step="any"
                                name="mwt_rheology"
                                value={formData.mwt_rheology}
                                onChange={handleChange}
                                required
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="pv">
                        <Form.Label column sm={4}>PV</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                step="any"
                                name="pv"
                                value={formData.pv}
                                onChange={handleChange}
                                required
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="yp">
                        <Form.Label column sm={4}>YP</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                step="any"
                                name="yp"
                                value={formData.yp}
                                onChange={handleChange}
                                required
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="fann3">
                        <Form.Label column sm={4}>3 rpm</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                step="any"
                                name="fann3"
                                value={formData.fann3}
                                onChange={handleChange}
                                required
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="fann6">
                        <Form.Label column sm={4}>6 rpm</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                step="any"
                                name="fann6"
                                value={formData.fann6}
                                onChange={handleChange}
                                required
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="fann300">
                        <Form.Label column sm={4}>300 rpm</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                step="any"
                                name="fann300"
                                value={formData.fann300}
                                onChange={handleChange}
                                required
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="fann600">
                        <Form.Label column sm={4}>600 rpm</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                step="any"
                                name="fann600"
                                value={formData.fann600}
                                onChange={handleChange}
                                required
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="cuttingsDiam">
                        <Form.Label column sm={4}>Cuttings</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                step="any"
                                name="cuttings_diam"
                                value={formData.cuttings_diam}
                                onChange={handleChange}
                                required
                            />
                        </Col>
                    </Form.Group>
                    <Button variant="primary" type="submit" style={{ marginTop: '20px' }}>
                        Save
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default AddRheology;
