import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Modal, Table, Button } from 'react-bootstrap';
import axios from 'axios';

const ViewBhaProperties = ({ show, handleClose, selectedItemId, bhaItemType }) => {
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables

    const [key, setKey] = useState('pipeBody');
    const [pipeBodyData, setPipeBodyData] = useState(null);
    const [connectionData, setConnectionData] = useState(null);
    const [tubularAssemblyData, setTubularAssemblyData] = useState(null);

    useEffect(() => {
        if (selectedItemId) {
            fetchPipeBodyData();
            fetchConnectionData();
            fetchTubularAssemblyData();
        }
    }, [selectedItemId]);

    const fetchPipeBodyData = async () => {
        try {
            let url;
            switch (bhaItemType) {
                case 'Drill pipe':
                    url = `${baseURL}/input/dp_library_id/${selectedItemId}`;
                    break;
                case 'HWDP':
                    url = `${baseURL}/input/hwdp_library_id/${selectedItemId}`;
                    break;
                case 'Drill collar':
                    url = `${baseURL}/input/drill_collar_library_id/${selectedItemId}`;
                    break;
                case 'Jar':
                    url = `${baseURL}/input/jar_library_id/${selectedItemId}`;
                    break;
                default:
                    throw new Error('Unknown BHA item type');
            }

            const response = await axios.get(url, {
                withCredentials: true,
            });
            setPipeBodyData(response.data);
            console.log('view pipe body data:', response.data);
        } catch (error) {
            console.error('Error fetching pipe body data:', error);
        }
    };

    const fetchConnectionData = async () => {
        try {
            const response = await axios.get(`${baseURL}/input/dp_library_id/${selectedItemId}`, {
                withCredentials: true,
            });
            setConnectionData(response.data);
        } catch (error) {
            console.error('Error fetching connection data:', error);
        }
    };

    const fetchTubularAssemblyData = async () => {
        try {
            const response = await axios.get(`${baseURL}/input/dp_library_id/${selectedItemId}`, {
                withCredentials: true,
            });
            setTubularAssemblyData(response.data);
        } catch (error) {
            console.error('Error fetching tubular assembly data:', error);
        }
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>View BHA Properties</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs id="bha-properties-tabs" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
                    <Tab eventKey="pipeBody" title="Pipe Body">
                        {pipeBodyData ? (
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Property</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(pipeBodyData).map((key) => (
                                        <tr key={key}>
                                            <td>{key}</td>
                                            <td>{pipeBodyData[key]}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        ) : (
                            <p>Loading...</p>
                        )}
                    </Tab>
                    <Tab eventKey="connection" title="Connection">
                        {connectionData ? (
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Property</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(connectionData).map((key) => (
                                        <tr key={key}>
                                            <td>{key}</td>
                                            <td>{connectionData[key]}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        ) : (
                            <p>Loading...</p>
                        )}
                    </Tab>
                    <Tab eventKey="tubularAssembly" title="Tubular Assembly">
                        {tubularAssemblyData ? (
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Property</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(tubularAssemblyData).map((key) => (
                                        <tr key={key}>
                                            <td>{key}</td>
                                            <td>{tubularAssemblyData[key]}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        ) : (
                            <p>Loading...</p>
                        )}
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ViewBhaProperties;
