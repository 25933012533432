import React from 'react';
import { Container, Card } from 'react-bootstrap';

const CookiesPage = () => {
    return (
        <Container className="my-5">
            <Card>
                <Card.Header>
                    <h2>Cookies Policy</h2>
                </Card.Header>
                <Card.Body>
                    <p>This policy explains how we use cookies on the Well Designer app:</p>
                    <h4>1. What are Cookies?</h4>
                    <p>Cookies are small text files stored on your device when you visit a website. They help us understand how users interact with the app.</p>
                    <h4>2. How We Use Cookies</h4>
                    <p>We use cookies to remember user preferences, analyze site traffic, and enhance the user experience.</p>
                    <h4>3. Managing Cookies</h4>
                    <p>You can disable cookies through your browser settings, but doing so may affect the functionality of the app.</p>
                    <h4>4. Third-Party Cookies</h4>
                    <p>We may use third-party cookies for analytics and performance tracking. These cookies are managed by the third-party providers.</p>
                    <h4>5. Changes to the Cookies Policy</h4>
                    <p>We may update our cookies policy as needed. Please review this page for the latest updates.</p>
                    <p>Last Updated: October 22, 2024</p>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default CookiesPage;
