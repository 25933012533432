import React, { useState } from 'react';
import { Form, InputGroup, FormControl, Button, Dropdown, Row, Col } from 'react-bootstrap';

const UnitConverter = () => {
    const [value, setValue] = useState('');
    const [conversionType, setConversionType] = useState('length');
    const [fromUnit, setFromUnit] = useState('feet');
    const [toUnit, setToUnit] = useState('meters');
    const [convertedValue, setConvertedValue] = useState(null);

    const conversionOptions = {
        length: ['feet', 'meters', 'inches', 'centimeters'],
        pressure: ['psi', 'kPa', 'bar'],
        volume: ['gallons', 'liters', 'barrels'],
    };

    const convertValue = () => {
        let converted;
        const val = parseFloat(value);
        if (isNaN(val)) {
            alert('Please enter a valid number');
            return;
        }

        switch (conversionType) {
            case 'length':
                converted = convertLength(val, fromUnit, toUnit);
                break;
            case 'pressure':
                converted = convertPressure(val, fromUnit, toUnit);
                break;
            case 'volume':
                converted = convertVolume(val, fromUnit, toUnit);
                break;
            default:
                converted = val;
        }

        setConvertedValue(converted);
    };

    const convertLength = (value, from, to) => {
        const lengthConversions = {
            feet: { meters: value * 0.3048, inches: value * 12, centimeters: value * 30.48 },
            meters: { feet: value / 0.3048, inches: value * 39.3701, centimeters: value * 100 },
            inches: { feet: value / 12, meters: value * 0.0254, centimeters: value * 2.54 },
            centimeters: { feet: value / 30.48, meters: value / 100, inches: value / 2.54 },
        };
        return lengthConversions[from][to];
    };

    const convertPressure = (value, from, to) => {
        const pressureConversions = {
            psi: { kPa: value * 6.89476, bar: value * 0.0689476 },
            kPa: { psi: value / 6.89476, bar: value * 0.01 },
            bar: { psi: value / 0.0689476, kPa: value * 100 },
        };
        return pressureConversions[from][to];
    };

    const convertVolume = (value, from, to) => {
        const volumeConversions = {
            gallons: { liters: value * 3.78541, barrels: value / 42 },
            liters: { gallons: value / 3.78541, barrels: value / 158.987 },
            barrels: { gallons: value * 42, liters: value * 158.987 },
        };
        return volumeConversions[from][to];
    };

    return (
        <Row>
            <Col md={6}>
            <h3>Unit Converter</h3>
            <Form>
                <InputGroup className="mb-3">
                    <FormControl
                        placeholder="Enter value"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        type="number"
                    />
                    <Dropdown onSelect={(eventKey) => setConversionType(eventKey)}>
                        <Dropdown.Toggle variant="secondary">
                            {conversionType.charAt(0).toUpperCase() + conversionType.slice(1)}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {Object.keys(conversionOptions).map((type) => (
                                <Dropdown.Item key={type} eventKey={type}>
                                    {type.charAt(0).toUpperCase() + type.slice(1)}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown onSelect={(eventKey) => setFromUnit(eventKey)}>
                        <Dropdown.Toggle variant="secondary">
                            {fromUnit}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {conversionOptions[conversionType].map((unit) => (
                                <Dropdown.Item key={unit} eventKey={unit}>
                                    {unit}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown onSelect={(eventKey) => setToUnit(eventKey)}>
                        <Dropdown.Toggle variant="secondary">
                            {toUnit}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {conversionOptions[conversionType].map((unit) => (
                                <Dropdown.Item key={unit} eventKey={unit}>
                                    {unit}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Button variant="primary" onClick={convertValue}>
                        Convert
                    </Button>
                </InputGroup>
            </Form>
            {convertedValue !== null && (
                <p>
                    {value} {fromUnit} is equal to {convertedValue.toFixed(2)} {toUnit}
                </p>
                )}
            </Col>
        </Row>
    );
};

export default UnitConverter;
