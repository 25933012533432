import { List, Datagrid, TextField, TextInput, EditButton, DeleteButton, ReferenceField } from 'react-admin';

const UserFilters = [
    <TextInput label="Search" source="q" alwaysOn />,
];

const WellDesignCaseList = () => (
    <List filters={UserFilters}>
        <Datagrid>
            <TextField source="id" aria-label='Case ID'/>
            <TextField source="well_id" />
            
            {/* ReferenceField for well_id */}
            <ReferenceField source="id" reference="wells" label="Well">
                <TextField source="well_name" />
            </ReferenceField>

            <ReferenceField source="id" reference="wellbores" label="Wellbore">
                <TextField source="wellbore_name" />
            </ReferenceField>

            <TextField source="wellbore_id" />

            <ReferenceField source="id" reference="units_selection" label="Units">
                <TextField source="units_type" />
            </ReferenceField>

            <TextField source="units_id" />
            <TextField source="well_design_case_name" />
            <TextField source="wd_date_created" label="Date created" />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export default WellDesignCaseList;

