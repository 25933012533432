import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Form, InputGroup, Button, Row, Col, Container } from 'react-bootstrap';
import { useAuth } from './AuthContext';

function TwoFactorVerification({ user_id}) {
    const [otp, setOtp] = useState('');
    const [error, setError] = useState('');
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const navigate = useNavigate();
    const { setIsAuthenticated, setIsTwoFactorVerified } = useAuth();

    const handleVerificationSuccess = () => {
        setIsTwoFactorVerified(true); // Update verification state to allow access to protected routes
        alert("Successfully logged in");
        navigate('/design_case'); // Redirect to the protected route
    };

    const handleVerify = async () => {
        console.log(`Sending request to ${baseURL}/verify_2fa`);

        try {
            const response = await axios.post(
                `${baseURL}/verify_2fa`,
                { otp, user_id },
                { withCredentials: true }
            );
            if (response.status === 200) {
                handleVerificationSuccess(); // Call the success handler
            }
        } catch (error) {
            setError('Invalid 2FA code. Please try again.');
        }
    };

    async function handleLogout() {
        try {
            const response = await axios.post(
                `${baseURL}/logout`,
                {},
                { withCredentials: true }
            );
            console.log(response.data.message);
            setIsAuthenticated(false);
            setIsTwoFactorVerified(false);
            navigate('/login');
        } catch (error) {
            console.error('Error during logout:', error);
        }
    }

    return (
        <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
            <Col md={6} className='bg-light p-3'>
                <h4 className="mb-4 text-center">To keep your account secure, we verify your identity.</h4>
                <p className="text-center">Please enter the code generated by your authenticator app.</p>
                <Row className="justify-content-center">
                    <InputGroup className="mb-3">
                        <Form.Control
                            type="text"
                            placeholder="Enter verification code"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            aria-label="Verification Code"
                        />
                        <Button variant="primary" onClick={handleVerify}>
                            Verify
                        </Button>
                        <Button variant="secondary" onClick={handleLogout}>
                            Cancel
                        </Button>
                    </InputGroup>
                    {error && <p className="text-danger text-center">{error}</p>}
                </Row>
            </Col>
        </Container>
    );
}

export default TwoFactorVerification;
