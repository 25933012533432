import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Alert } from 'react-bootstrap';

function PasswordChange() {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordFeedback, setPasswordFeedback] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const baseURL = process.env.REACT_APP_API_BASE_URL;

    const handlePasswordChange = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        if (newPassword !== confirmPassword) {
            setError('New password and confirmation do not match.');
            return;
        }

        // Ensure password strength before sending to the server
        if (passwordFeedback !== 'Password is strong.') {
            setError('Please enter a stronger password.');
            return;
        }

        try {
            const response = await axios.post(
                `${baseURL}/change_password`,
                {
                    currentPassword,
                    newPassword,
                },
                {
                    withCredentials: true,
                }
            );

            if (response.status === 200) {
                setSuccess('Password changed successfully.');
                setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('');
                setPasswordFeedback('');
            }
        } catch (error) {
            setError('Failed to change password. Please try again.');
        }
    };

    function isPasswordStrong(password) {
        const minLength = 8;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumbers = /\d/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

        if (password.length < minLength) {
            return "Password must be at least 8 characters long.";
        }
        if (!hasUpperCase) {
            return "Password must contain at least one uppercase letter.";
        }
        if (!hasLowerCase) {
            return "Password must contain at least one lowercase letter.";
        }
        if (!hasNumbers) {
            return "Password must contain at least one digit.";
        }
        if (!hasSpecialChar) {
            return "Password must contain at least one special character.";
        }

        return "Password is strong.";
    }

    const handleNewPasswordChange = (e) => {
        const password = e.target.value;
        setNewPassword(password);
        setPasswordFeedback(isPasswordStrong(password));
    };

    return (
        <div className="container mt-4">
            <h6 className='text-primary'>Change Password</h6>
            {success && <Alert variant="success">{success}</Alert>}
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handlePasswordChange}>
                <Form.Group controlId="currentPassword">
                    <Form.Label>Current Password</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Enter current password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="newPassword" className="mt-3">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Enter new password"
                        value={newPassword}
                        onChange={handleNewPasswordChange}
                        required
                    />
                    <Form.Text className="text-muted">
                        {passwordFeedback}
                    </Form.Text>
                </Form.Group>

                <Form.Group controlId="confirmPassword" className="mt-3">
                    <Form.Label>Confirm New Password</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Confirm new password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                </Form.Group>

                <Button variant="primary" type="submit" className="mt-3">
                    Change Password
                </Button>
            </Form>
        </div>
    );
}

export default PasswordChange;
