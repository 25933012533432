// import { useEffect, useState } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Nav, Navbar, NavDropdown, Button, Dropdown, Container } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useAuth } from "../../components/admin/AuthContext";

function LoggedInLinks() {

    return (
        <>
            <Nav.Link as={Link} to="/">Home</Nav.Link>
            <Nav.Link as={Link} to="/about">Applications</Nav.Link>
            <Nav.Link as={Link} to="/pricing">Pricing</Nav.Link>
            <NavDropdown title="Design Inputs" id="new_design-nav-dropdown">
                <NavDropdown.Item as={Link} to="/design_case">Design case</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/well_information">Well information</NavDropdown.Item>
            </NavDropdown>
            {/* <Nav.Link href="#link">Reports</Nav.Link> */}
            <NavDropdown title="Modules" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to="/hydraulics">Hydraulics</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/swab_and_surge">Swab and Surge</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.3">Torque and Drag</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/kick_tolerance">Kick Tolerance</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/casing_design">Casing Design</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Tools" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to="/casing_dimensions_strength">Dimensions and strengths</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/capacities">Capacities</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/compressibility">Mud compressibility</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/interpolation">Interpolate surveys</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/units">Units converter</NavDropdown.Item>
            </NavDropdown>
            {/* <Nav.Link href="#">Support</Nav.Link> */}
        </>
    );
}

function LoggedOutLinks() {
    return (
        <>
            <Nav.Link as={Link} to="/">Home</Nav.Link>
            <Nav.Link as={Link} to="/about">Applications</Nav.Link>
            <Nav.Link as={Link} to="/pricing">Pricing</Nav.Link>

        </>
    );
}

function LoggedOutLinksRight() {
    return (
        <>
            <Button className="btn btn-outline-light" as={Link} to="/login">Login</Button>
            <Button variant="primary" as={Link} to="/register">Sign Up</Button>
        </>
    );
}

function Header({ isAuthenticated, setIsAuthenticated, isTwoFactorVerified, setIsTwoFactorVerified, email, fullName }) {

    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables
    const navigate = useNavigate();
    const { isAdmin } = useAuth();

    console.log('isTwoFactorVerified in Header', isTwoFactorVerified);
    
    async function handleLogout() {
        try {
            const response = await axios.post(
                `${baseURL}/logout`,
                {},
                {
                    withCredentials: true, // Include credentials to ensure the HttpOnly cookie is included
                }
            );
            console.log(response.data.message);
            setIsAuthenticated(false);
            setIsTwoFactorVerified(false);
            navigate('/login');
        } catch (error) {
            console.error('Error during logout:', error);
        }
    }

    return (
        <Navbar expand="lg" bg="primary" data-bs-theme="dark">
            <Container>
                <Navbar.Brand as={Link} to="/">WellDesigner</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        {!isTwoFactorVerified ? (
                            <LoggedOutLinks />
                        ) : (
                            <LoggedInLinks />
                        )}
                    </Nav>
                    {!isTwoFactorVerified ? (
                        <>
                            <LoggedOutLinksRight />
                        </>
                    ) : (
                            <>
                                <Nav className="align-items-center text-light">
                                    {/* <Nav.Link href="/profile">My profile</Nav.Link> */}
                                    
                                    {fullName ? fullName : email}

                                    <Dropdown>
                                        <Dropdown.Toggle variant="primary">
                                            <span className="material-symbols-outlined rounded-circle" id="id_profile_links" >
                                                person
                                            </span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item as={Link} to="/profile">
                                                My account
                                            </Dropdown.Item>
                                            <Dropdown.Item as={Link} to="/design_case">
                                                My Designs
                                            </Dropdown.Item>
                                            {isAdmin && (
                                                <Dropdown.Item as={Link} to="/admin">
                                                    My Admin
                                                </Dropdown.Item>
                                            )}
                                            <Dropdown.Item onClick={handleLogout}>
                                                Logout
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Nav>
                            </>
                    )}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;