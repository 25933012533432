import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Alert from 'react-bootstrap/Alert';
import Dropzone from 'react-dropzone';
import Papa from 'papaparse';
import axios from 'axios';
import HelpComponent from '../../help/helpComponent';

function AddNewHWDPForm({ show, handleClose, onNewHWDPAdded }) {
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables

    const [key, setKey] = useState('hwdp');
    const [showSuccess, setShowSuccess] = useState(false);
    const [setFileData] = useState(null);
    const [uploadMethod, setUploadMethod] = useState('manual');
    const [showHelp, setShowHelp] = useState(false);

    const [formData, setFormData] = useState({
        hwdp_size: '',
        hwdp_int_dia: '',
        hwdp_wall: '',
        hwdp_as: '',
        hwdp_cent_upset_od: '',
        hwdp_elev_upset_od: '',
        hwdp_tube_ten: '',
        hwdp_tor_yield: '',
        hwdp_conn: '',
        hwdp_conn_od: '',
        hwdp_conn_id: '',
        hwdp_conn_ten: '',
        hwdp_conn_yld: '',
        hwdp_wt_incl_tj: '',
        hwdp_mu_tor: '',
        hwdp_name: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        parseFile(file);
    };

    const handleDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        parseFile(file);
    };

    const parseFile = (file) => {
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: (result) => {
                const data = result.data[0];
                setFormData({ ...formData, ...data });
                setUploadMethod('manual');
            },
            error: (error) => {
                console.error('Error parsing file:', error);
            }
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        axios({
            method: 'POST',
            url: `${baseURL}/input/hwdp_library`,
            withCredentials: true,
            data: formData
        })
            .then(response => {
                if (response.status === 201) {
                    onNewHWDPAdded();
                    setShowSuccess(true);
                }
            })
            .catch(error => {
                console.error('Error adding new HWDP:', error);
            });
    };

    const handleUploadMethodChange = (method) => {
        setUploadMethod(method);
        setFileData(null);
        setFormData({
            hwdp_size: '',
            hwdp_int_dia: '',
            hwdp_wall: '',
            hwdp_as: '',
            hwdp_cent_upset_od: '',
            hwdp_elev_upset_od: '',
            hwdp_tube_ten: '',
            hwdp_tor_yield: '',
            hwdp_conn: '',
            hwdp_conn_od: '',
            hwdp_conn_id: '',
            hwdp_conn_ten: '',
            hwdp_conn_yld: '',
            hwdp_wt_incl_tj: '',
            hwdp_mu_tor: '',
            hwdp_name: ''
        });
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add a new HWDP to the database</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="float-end">
                    <Button variant="outline-secondary" onClick={() => setShowHelp(true)} style={{ marginLeft: '10px' }}>
                        Help
                    </Button>
                </div>
                {showSuccess && (
                    <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible>
                        You have successfully added a new HWDP record to the database.
                        It should now be available for selection in the Add BHA Item form.
                    </Alert>
                )}
                <Tabs
                    id="controlled-tab-header"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3 mt-5"
                >
                    <Tab eventKey="hwdp" title="HWDP">
                        <div className="upload-method-selection mb-3">
                            <Button variant="outline-primary" onClick={() => handleUploadMethodChange('manual')}>
                                Enter Data Manually
                            </Button>
                            <Button variant="outline-primary" onClick={() => handleUploadMethodChange('file')} style={{ marginLeft: '10px' }}>
                                Upload File
                            </Button>
                            <Button variant="outline-primary" onClick={() => handleUploadMethodChange('dragdrop')} style={{ marginLeft: '10px' }}>
                                Drag & Drop File
                            </Button>
                        </div>
                        {uploadMethod === 'manual' && (
                            <Form onSubmit={handleSubmit}>
                                <Form.Group as={Row} controlId="hwdp_size">
                                    <Form.Label column sm={4}>HWDP Size</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            type="text"
                                            name="hwdp_size"
                                            value={formData.hwdp_size}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="hwdp_int_dia">
                                    <Form.Label column sm={4}>Internal Diameter</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            type="text"
                                            name="hwdp_int_dia"
                                            value={formData.hwdp_int_dia}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="hwdp_wall">
                                    <Form.Label column sm={4}>Wall Thickness</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            type="text"
                                            name="hwdp_wall"
                                            value={formData.hwdp_wall}
                                            onChange={handleInputChange}
                                            required
                                            disabled
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="hwdp_as">
                                    <Form.Label column sm={4}>Area of Section</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            type="text"
                                            name="hwdp_as"
                                            value={formData.hwdp_as}
                                            onChange={handleInputChange}
                                            required
                                            disabled
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="hwdp_cent_upset_od">
                                    <Form.Label column sm={4}>Center Upset OD</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            type="text"
                                            name="hwdp_cent_upset_od"
                                            value={formData.hwdp_cent_upset_od}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="hwdp_elev_upset_od">
                                    <Form.Label column sm={4}>Elevator Upset OD</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            type="text"
                                            name="hwdp_elev_upset_od"
                                            value={formData.hwdp_elev_upset_od}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="hwdp_tube_ten">
                                    <Form.Label column sm={4}>Tube Tension</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            type="text"
                                            name="hwdp_tube_ten"
                                            value={formData.hwdp_tube_ten}
                                            onChange={handleInputChange}
                                            required
                                            disabled
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="hwdp_tor_yield">
                                    <Form.Label column sm={4}>Torque Yield</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            type="text"
                                            name="hwdp_tor_yield"
                                            value={formData.hwdp_tor_yield}
                                            onChange={handleInputChange}
                                            required
                                            disabled
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="hwdp_conn">
                                    <Form.Label column sm={4}>Connection</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            type="text"
                                            name="hwdp_conn"
                                            value={formData.hwdp_conn}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="hwdp_conn_od">
                                    <Form.Label column sm={4}>Connection OD</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            type="text"
                                            name="hwdp_conn_od"
                                            value={formData.hwdp_conn_od}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="hwdp_conn_id">
                                    <Form.Label column sm={4}>Connection ID</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            type="text"
                                            name="hwdp_conn_id"
                                            value={formData.hwdp_conn_id}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="hwdp_conn_ten">
                                    <Form.Label column sm={4}>Connection Tension</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            type="text"
                                            name="hwdp_conn_ten"
                                            value={formData.hwdp_conn_ten}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="hwdp_conn_yld">
                                    <Form.Label column sm={4}>Connection Yield</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            type="text"
                                            name="hwdp_conn_yld"
                                            value={formData.hwdp_conn_yld}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="hwdp_wt_incl_tj">
                                    <Form.Label column sm={4}>Weight (Including Tool Joint)</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            type="text"
                                            name="hwdp_wt_incl_tj"
                                            value={formData.hwdp_wt_incl_tj}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="hwdp_mu_tor">
                                    <Form.Label column sm={4}>Make-Up Torque</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            type="text"
                                            name="hwdp_mu_tor"
                                            value={formData.hwdp_mu_tor}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="hwdp_name">
                                    <Form.Label column sm={4}>HWDP Name</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            type="text"
                                            name="hwdp_name"
                                            value={formData.hwdp_name}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Col>
                                </Form.Group>
                                <div className="float-end">
                                    <Button variant="primary" type="submit">
                                        Add to Database
                                    </Button>
                                </div>
                            </Form>
                        )}
                        {uploadMethod === 'file' && (
                            <Form>
                                <Form.Group>
                                    <Form.File
                                        id="fileUpload"
                                        label="Upload a data file"
                                        onChange={handleFileUpload}
                                        custom
                                    />
                                </Form.Group>
                            </Form>
                        )}
                        {uploadMethod === 'dragdrop' && (
                            <Dropzone onDrop={handleDrop}>
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()} style={{ border: '2px dashed #007bff', padding: '20px', textAlign: 'center' }}>
                                        <input {...getInputProps()} />
                                        <p>Drag & drop a file here, or click to select one</p>
                                    </div>
                                )}
                            </Dropzone>
                        )}
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
            <HelpComponent
                show={showHelp}
                onHide={() => setShowHelp(false)}
                title="Add HWDP Form Help"
                content={
                    <div>
                        <p>This form allows you to add new HWDP records to the database.</p>
                        <p>You can choose to enter the data manually, upload a file, or drag and drop a file.</p>
                        <p>For manual entry, fill in all the required fields and click 'Add to Database'.</p>
                        <p>For file upload, click 'Upload a data file', select your file, and click 'Upload'.</p>
                        <p>For drag and drop, drag a file into the designated area, and the data will be populated automatically.</p>
                    </div>
                }
            />
        </Modal>
    );
}

export default AddNewHWDPForm;
