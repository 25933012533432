import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const TermsOfUse = () => {
    return (
        <Container className="my-5">
            <Row>
                <Col>
                    <div>
                        <h2 className='text-primary'>Terms of Use</h2>
                    </div>
                    <div className='p-3'>
                        <p>Welcome to the Well Designer app. By using our application, you agree to comply with the following terms and conditions:</p>
                        <h6 className='text-primary'>1. Use of the Application</h6>
                        <p>The application is intended for professionals in the oil and gas industry. You agree to use the app for lawful purposes and in accordance with these terms.</p>
                        <h6 className='text-primary'>2. User Responsibilities</h6>
                        <p>Users are responsible for the accuracy of the data they input and the interpretations derived from the results.</p>
                        <h6 className='text-primary'>3. Intellectual Property</h6>
                        <p>All content, including code, designs, and data, is the intellectual property of the Well Designer team. You may not use or distribute any part of the app without permission.</p>
                        <h6 className='text-primary'>4. Disclaimer of Warranties</h6>
                        <p>The app is provided "as is" without warranties of any kind. We do not guarantee accuracy or reliability of results.</p>
                        <h6 className='text-primary'>5. Limitation of Liability</h6>
                        <p>We are not liable for any damages resulting from the use of this application. Users assume full responsibility for their decisions based on app outputs.</p>
                        <h6 className='text-primary'>6. Changes to the Terms</h6>
                        <p>We reserve the right to update these terms at any time. Continued use of the app constitutes acceptance of the revised terms.</p>
                        <p>Last Updated: October 22, 2024</p>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default TermsOfUse;
