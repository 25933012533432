import { Admin, Resource, Layout } from 'react-admin';
// import { Route } from 'react-router-dom';
// import simpleRestProvider from 'ra-data-simple-rest';
// import { fetchUtils } from 'ra-core';
import Dashboard from './dashboard/Dashboard';
import CustomMenu from './CustomMenu';
import UserList from './UserList';
import UserEdit from './UserEdit';
import UserCreate from './UserCreate';
// import Profile from './Profile';
import WellDesignCaseList from './WellDesignCaseList';
import WellDesignCaseEdit from './WellDesignCaseEdit';
import customDataProvider from './dataProvider';

// Custom fetchJson function to include withCredentials
// const fetchJson = (url, options = {}) => {
//     options.credentials = 'include'; // Enable withCredentials
//     if (!options.headers) {
//         options.headers = new Headers({ Accept: 'application/json' });
//     }
//     return fetchUtils.fetchJson(url, options);
// };

// const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables

// const dataProvider = simpleRestProvider('http://localhost:5000', fetchJson);
// const dataProvider = simpleRestProvider(`${baseURL}`, fetchJson);
const MyLayout = (props) => <Layout {...props} menu={CustomMenu} />;

const AdminPanel = () => (
    <Admin
            layout={MyLayout}    
            basename="/admin"
            // dataProvider={dataProvider}
            dataProvider={customDataProvider}
            dashboard={Dashboard}
            menu={CustomMenu}
        >
        <Resource name="users" list={UserList} edit={UserEdit} create={UserCreate} />
        <Resource name="well_design_cases" list={WellDesignCaseList} edit={WellDesignCaseEdit} />
        <Resource name="well_design_cases" list={WellDesignCaseList} edit={WellDesignCaseEdit} />

        {/* <Resource name="wellbores" list={ListGuesser} /> */}

            {/* <CustomRoutes>
                <Route path="/profile" element={<Profile />} />
                <Route path="/dashboard" element={<Dashboard />} />

            </CustomRoutes> */}
        </Admin>
    
);

export default AdminPanel;
