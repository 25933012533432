import React from "react";
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// import webPageImage from '../images/web_page.png'; // Import the image

import LandingCarousel from "../components/landingCarousel";

export default function LandingPage() {

    function myFunction() {
        var dots = document.getElementById("dots");
        var moreText = document.getElementById("more");
        var btnText = document.getElementById("myBtn");

        if (dots.style.display === "none") {
            dots.style.display = "inline";
            btnText.innerHTML = "Read more";
            moreText.style.display = "none";
        } else {
            dots.style.display = "none";
            btnText.innerHTML = "Read less";
            moreText.style.display = "inline";
        }
    }

    return (
        <Container className="container vh-100 mt-5">
                {/* <Row>
                    <Col>
                        <LandingCarousel />

                    </Col>
                </Row> */}
                <Row>
                    <Col >
                        <p className="text-uppercase fw-bold text-primary">WellDesigner<span>&#8482;</span> Suite of Applications</p>

                        <div className="grid gap-0 mt-3 mb-3 text-dark">
                            <h4>Cutting-edge <br />well engineering design <br />software, and utilities</h4>
                        </div>
                        <p>Welcome to our comprehensive well engineering toolbox!</p>
                        <p>Are you seeking efficient solutions for hydraulic calculations, swab and surge analysis, or precise casing design? Look no further! Our website offers a suite of cutting-edge applications tailored to streamline your workflows and optimize your operations.
                        <span id="dots">...</span></p>
                        <span id="more">
                            <p>Explore our diverse range of tools, meticulously crafted to meet the demands of the modern well engineer. From torque and drag simulations to casing and drill pipe table lookups, we've got you covered. Calculate hole capacities, casing dimensions, and annular capacities with ease, all in one convenient platform.</p>
                            <p>Whether you're a seasoned professional or just starting out in the field, our user-friendly interface and robust functionalities ensure that you can tackle any challenge with confidence.</p>
                            <p>Join the ranks of satisfied users who have revolutionized their well engineering processes with our tools. Start exploring now and unlock the power of precision in your projects!</p>
                            </span>
                        
                        <div className="grid gap-3 mt-5">
                            <p><Button variant="primary" onClick={myFunction} id="myBtn">Read more</Button> <Link to="/register" className="btn btn-outline-primary">Get started free</Link> </p>
                            {/* <p>Links</p> */}
                            {/* <p><Link to="/pricing" className="btn btn-outline-primary">View pricing</Link></p> */}
                        </div>
                            <p>
                            Get started with our <button type="button" className="text-decoration-none btn btn-link p-0">free tools</button> or get more with our <Link to="/register" className="text-decoration-none">premium software.</Link>
                        </p>
                    </Col>
                    <Col md={6}>
                        <div >
                            {/* Use the imported image */}
                            {/* <img src={webPageImage} className="img-fluid my-0" style={{ width: '100%', height: 'auto', }} alt="Web Page" /> */}
                            <LandingCarousel />
                        </div>
                    </Col>
                </Row>
            </Container>
    );
}
