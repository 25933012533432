import React from "react";
import BhaTable from "./bhaTable";
// import useToken from '../admin/useToken'; // Import the custom hook
import useToken from "../../admin/useToken";
// import { GlobalStateContext } from "../GlobalStateContext";


const BhaHeader = ({ wellboreId, wellId, wellDesignCaseId }) => {
    const { token, setToken } = useToken(); // Use the custom hook to manage token state
    // const { selectedItems } = useContext(GlobalStateContext);
    // const openHoleParameters = selectedItems?.openHoleParameters;

    return (
        <div className="container">


            <div className="row">
                <div className="col">
                    <BhaTable wellId={wellId} wellboreId={wellboreId} wellDesignCaseId={wellDesignCaseId} token={token} setToken={setToken} />
                </div>
                {/* <div className="col col-lg-2">
                    Hole depth: {openHoleParameters.depth_md}
                </div> */}
            </div>
        </div>
    );
};

export default BhaHeader;
