import React, { createContext, useCallback, useContext } from "react";
import axios from "axios";

// Create the context
const CoordinateConversionContext = createContext();

export function useCoordinateConversion() {
    return useContext(CoordinateConversionContext);
}

export function CoordinateConversionProvider({ children, baseURL }) {

    const CoordinateConverter = useCallback(async (initialNorthing, initialEasting, epsgCode) => {

        try {
            const response = await axios.post(`${baseURL}/input/convert-coordinates`, {
                easting: parseFloat(initialEasting),
                northing: parseFloat(initialNorthing),
                epsg_code: epsgCode,
            });

            const { latitude, longitude, declination } = response.data;
            return { latitude, longitude, declination };
        } catch (err) {
            console.error('Error converting coordinates: ' + err.message);
            throw new Error('Error converting coordinates');
        }
    },[baseURL]);

    return (
        <CoordinateConversionContext.Provider value={{
            CoordinateConverter
            
        }}>
            {children}
        </CoordinateConversionContext.Provider>
    );
}
