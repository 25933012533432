import React from 'react';
import { useHoleSection } from '../../components/HoleSectionProvider';

import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';

function SidebarModules() {
    const { holeSection } = useHoleSection();

    return (

        <Nav defaultActiveKey="#" className="flex-column">
            <Nav.Link
                as={Link}
                to={holeSection.length > 0 ? "/hydraulics" : "#"}
                disabled={!holeSection.length > 0}
            >
                Hydraulics
            </Nav.Link>
            <Nav.Link
                as={Link}
                to={holeSection.length > 0 ? "/swab_and_surge" : "#"}
                disabled={!holeSection.length > 0}
            >
                Swab and Surge
            </Nav.Link>
            <Nav.Link
                as={Link}
                to={holeSection.length > 0 ? "/torque_and_drag" : "#"}
                disabled={!holeSection.length > 0}
            >
                Torque and Drag
            </Nav.Link>
            <Nav.Link
                as={Link}
                to={holeSection.length > 0 ? "/casing_design" : "#"}
                disabled={!holeSection.length > 0}
            >
                Casing Design
            </Nav.Link>
            <Nav.Link
                as={Link}
                to={holeSection.length > 0 ? "/kick_tolerance" : "#"}
                disabled={!holeSection.length > 0}
            >
                Kick Tolerance
            </Nav.Link>

        </Nav>
    );
}

export default SidebarModules;