import React from 'react';

import SidebarInputs from './sidebarInputs';
import SidebarModules from './sidebarModules';
import { Nav } from 'react-bootstrap';

function LeftSidebar() {
    return (

        <>
            <Nav className="flex-column bg-white sidebar border-end">

                <div className='mt-5'>Inputs</div>
                    <span><SidebarInputs /></span>
                            
                <div>Modules</div>
                    <span><SidebarModules /></span>
            </Nav>
        </>
    );
}

export default LeftSidebar;