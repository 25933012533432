import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import swabSurgeImage from '../images/ecd_surge.png'; // Adjust the path as needed
import casingDesignImage from '../images/casing_design_burst_surface.png'; // Adjust the path as needed
import trajectoryImage from '../images/trajectory_plan_view.png'; // Adjust the path as needed


const About = () => {
    return (
        <Container className="my-4">
            <h1 className="mb-4 text-primary">About</h1>
            <p>
                Welcome to our application, your comprehensive solution for well engineering calculations and analysis. Our platform offers a suite of modules and tools designed to support engineers in optimizing well design, ensuring safety, and improving operational efficiency. Here’s a breakdown of the key modules and tools available:
            </p>

            <h2 className="mt-4  text-primary">Modules</h2>
            <Row className="gy-3">
                <Col md={6}>
                    <Card>
                        <Card.Img variant="top" src={swabSurgeImage} alt="Example" />
                        <Card.Body>
                            <Card.Title>Hydraulics Modelling</Card.Title>
                            <Card.Text>
                                This module provides detailed analysis of fluid flow dynamics within the wellbore. It helps in calculating pressure losses, optimizing drilling fluid properties, and determining the most efficient flow rates to ensure effective hole cleaning and stability.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card>
                        <Card.Img variant="top" src={swabSurgeImage} alt="Example" />
                        <Card.Body>
                            <Card.Title>Swab and Surge Analysis</Card.Title>
                            <Card.Text>
                                Understand the impact of tripping operations on wellbore pressures with our Swab and Surge Analysis module. It calculates the changes in pressure due to pipe movement in and out of the well, allowing users to mitigate risks of formation damage or well control issues.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Kick Tolerance Calculation</Card.Title>
                            <Card.Text>
                                Our Kick Tolerance module assists in evaluating the maximum volume of gas or fluid influx that the well can handle before risking a blowout. It factors in formation pressures, kick intensity, and other critical parameters to ensure well control is maintained.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card>
                        <Card.Img variant="top" src={casingDesignImage} alt="Example" />
                        <Card.Body>
                            <Card.Title>Casing Design</Card.Title>
                            <Card.Text>
                                This module simplifies the process of selecting appropriate casing sizes and strengths based on well conditions. It helps to ensure wellbore integrity and prevent collapse, burst, and tensile failures by considering various design factors.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card>
                        <Card.Img variant="top" src={trajectoryImage} alt="Example" />
                        <Card.Body>
                            <Card.Title>Well Trajectory Design</Card.Title>
                            <Card.Text>
                                Plan and design precise well paths with our Well Trajectory Design module. It allows for the calculation of directional surveys, ensuring that the well reaches the target zone with the desired inclination and azimuth, while minimizing risks of collision and sidetracking.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Torque and Drag Analysis</Card.Title>
                            <Card.Text>
                                Analyze the frictional forces encountered during drilling operations with our Torque and Drag module. This tool helps to predict the mechanical stresses on the drill string, enabling optimized drilling and tripping practices while reducing risks of stuck pipe and excessive wear.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <h2 className="mt-4">Tools & Calculators</h2>
            <Row className="gy-3">
                <Col md={6}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Units Converter</Card.Title>
                            <Card.Text>
                                Convert between different measurement units with ease using our built-in units converter. It supports a wide range of units commonly used in well engineering, ensuring accurate data analysis and communication.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Survey Interpolation</Card.Title>
                            <Card.Text>
                                Generate accurate depth and inclination measurements between survey points using our Survey Interpolation tool. This feature is crucial for detailed trajectory planning and calculating true vertical depths (TVDs) at various points along the well path.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Capacities Calculator</Card.Title>
                            <Card.Text>
                                Calculate pipe, annular, and tank capacities quickly and accurately. This tool assists in determining the volume of fluids needed for operations such as mud displacement, cementing, and well control.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Casing Lookup Table</Card.Title>
                            <Card.Text>
                                Our Casing Lookup Table provides quick access to standard casing dimensions and strength properties. It offers detailed specifications to assist in selecting the right casing based on operational requirements and well conditions.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <p className="mt-4">
                Each module and tool is designed to provide accurate and reliable calculations, making it easier for engineers to make informed decisions throughout the well lifecycle. Our goal is to empower you with the insights and tools needed to achieve safer, more efficient drilling operations.
            </p>
        </Container>
    );
};

export default About;
