import React, { } from "react";
import UnitConverter from "../components/engineering_tools/UnitConverter";

export default function UnitConverterPage() {
    

    return (
        <div>
            <div className="container h-50">
                <div className="row h-50">
                    <div className="col-12">

                        {/* <h1>Unit Converter</h1> */}
                        <UnitConverter />
                    </div>
                </div>
            </div>
        </div>
    );
}