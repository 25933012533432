import React, { } from "react";

// import { Link } from 'react-router-dom';

export default function SignupFeeback() {

    return (
        <div>
            <div className="container h-50">
                <div className="row h-50">
                    <div className="col-12">
                        <h1>Welcome to WellDesign Applications</h1>
                        <h2>Thanks for joining!</h2>
                        <p>You have successfully created an account. Please check your email to confirm your account.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}