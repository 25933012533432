import React, { } from "react";

export default function PrivatePage() {

    return (
        <div>
            <div className="container h-100">
                <div className="row h-100">
                    <div className="col-12">
                        <h1>Welcome to WellDesign Applications</h1>
                        <h2>Thanks for joining!</h2>
                        <p>Perform a design</p>
                    </div>

                </div>
            </div>
        </div>
    );
}