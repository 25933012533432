import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col, Spinner, Alert } from "react-bootstrap";
import axios from "axios";

const AddEarthModelForm = ({ show, handleClose, wellId, wellboreId, wellDesignCaseId, onEarthDataAdded }) => {
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables

    const [tvd, setTvd] = useState("");
    const [porePressure, setPorePressure] = useState("");
    const [fractureGradient, setFractureGradient] = useState("");
    const [temp, setTemp] = useState("");
    const [fluidGrad, setFluidGrad] = useState("");
    const [formationTypeId, setFormationTypeId] = useState("");
    const [formationNameId, setFormationNameId] = useState("");
    const [formationTypes, setFormationTypes] = useState([]);
    const [formationNames, setFormationNames] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const formationTypesResponse = await axios.get(`${baseURL}/input/formation_type`, {
                    withCredentials: true
                });
                setFormationTypes(formationTypesResponse.data);

                const formationNamesResponse = await axios.get(`${baseURL}/input/formations`, {
                    withCredentials: true
                });
                setFormationNames(formationNamesResponse.data);

                setLoading(false);
            } catch (error) {
                setError("Error fetching formation data.");
                setLoading(false);
                console.error("Error fetching formation data:", error);
            }
        };

        fetchData();
    }, [baseURL]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const newEarthData = {
                well_id: parseFloat(wellId),
                wellbore_id: parseFloat(wellboreId),
                well_design_case_id: parseFloat(wellDesignCaseId),
                tvd: parseFloat(tvd),
                pore_press: parseFloat(porePressure),
                frac_grad: parseFloat(fractureGradient),
                temp: parseFloat(temp),
                gas_grad: parseFloat(fluidGrad),
                formation_type_id: parseInt(formationTypeId),
                formation_name_id: parseInt(formationNameId)
            };

            await axios.post(`${baseURL}/input/earth_model`, newEarthData, {
                withCredentials: true
            });
            onEarthDataAdded();
            handleClose();
        } catch (error) {
            setError("Error adding earth data.");
            console.error("Error adding earth data:", error);
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add Earth Data</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <Spinner animation="border" />
                ) : error ? (
                    <Alert variant="danger">{error}</Alert>
                ) : (
                    <Form onSubmit={handleSubmit}>
                        <Form.Group as={Row} controlId="formTvd">
                            <Form.Label column sm={4}>TVD</Form.Label>
                            <Col sm={8}>
                                <Form.Control type="number" step="0.01" value={tvd} onChange={(e) => setTvd(e.target.value)} required />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formationType">
                            <Form.Label column sm={4}>Formation Type</Form.Label>
                            <Col sm={8}>
                                <Form.Control as="select" value={formationTypeId} onChange={(e) => setFormationTypeId(e.target.value)} required>
                                    <option value="">Choose...</option>
                                    {formationTypes.map(type => (
                                        <option key={type.formation_type_id} value={type.formation_type_id}>
                                            {type.formation_type}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formationName">
                            <Form.Label column sm={4}>Formation Name</Form.Label>
                            <Col sm={8}>
                                <Form.Control as="select" value={formationNameId} onChange={(e) => setFormationNameId(e.target.value)} required>
                                    <option value="" disabled>Choose...</option>
                                    <option value="new">Add a new formation</option>
                                    {formationNames.map((name) => (
                                        <option key={name.formation_name_id} value={name.formation_name_id}>
                                            {name.formation_name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formPorePressure">
                            <Form.Label column sm={4}>Pore Press Grad</Form.Label>
                            <Col sm={8}>
                                <Form.Control type="number" step="0.01" value={porePressure} onChange={(e) => setPorePressure(e.target.value)} required />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="fracGradient">
                            <Form.Label column sm={4}>Fracture Gradient</Form.Label>
                            <Col sm={8}>
                                <Form.Control type="number" step="0.01" value={fractureGradient} onChange={(e) => setFractureGradient(e.target.value)} required />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="Temp">
                            <Form.Label column sm={4}>Temperature</Form.Label>
                            <Col sm={8}>
                                <Form.Control type="number" step="0.01" value={temp} onChange={(e) => setTemp(e.target.value)} required />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="FluidGrad">
                            <Form.Label column sm={4}>Fluid Gradient</Form.Label>
                            <Col sm={8}>
                                <Form.Control type="number" step="0.01" value={fluidGrad} onChange={(e) => setFluidGrad(e.target.value)} required />
                            </Col>
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            Save Data
                        </Button>
                    </Form>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default AddEarthModelForm;
