import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Table, Alert } from 'react-bootstrap';

export default function FluidCompressibilityEstimator() {
    const [fluidType, setFluidType] = useState('water-based');
    const [initialDensity, setInitialDensity] = useState('');
    const [initialPressure, setInitialPressure] = useState('');
    const [finalPressure, setFinalPressure] = useState('');
    const [deltaDensity, setDeltaDensity] = useState('');
    const [compressibility, setCompressibility] = useState(null);
    const [providedCompressibility, setProvidedCompressibility] = useState('');
    const [initialVolume, setInitialVolume] = useState('');
    const [deltaVolume, setDeltaVolume] = useState(null);
    const [error, setError] = useState('');

    const calculateCompressibility = () => {
        try {
            const density = parseFloat(initialDensity);
            const P1 = parseFloat(initialPressure);
            const P2 = parseFloat(finalPressure);
            const deltaRho = parseFloat(deltaDensity);

            if (!density || !P1 || !P2 || !deltaRho) {
                setError('Please enter valid values for all fields.');
                setCompressibility(null);
                return;
            }

            const deltaP = P2 - P1;
            const compressibilityValue = (1 / density) * (deltaRho / deltaP);

            setCompressibility(compressibilityValue);
            setError('');
        } catch (err) {
            setError('An error occurred while calculating compressibility.');
            setCompressibility(null);
        }
    };

    const calculateVolumeChange = () => {
        try {
            const C_f = parseFloat(providedCompressibility);
            const V0 = parseFloat(initialVolume);
            const P1 = parseFloat(initialPressure);
            const P2 = parseFloat(finalPressure);

            if (!C_f || !V0 || !P1 || !P2) {
                setError('Please enter valid values for compressibility, volume, and pressures.');
                setDeltaVolume(null);
                return;
            }

            const deltaP = P2 - P1;
            const deltaV = -C_f * V0 * deltaP; // Volume change formula

            setDeltaVolume(deltaV);
            setError('');
        } catch (err) {
            setError('An error occurred while calculating volume change.');
            setDeltaVolume(null);
        }
    };

    const handleReset = () => {
        setFluidType('water-based');
        setInitialDensity('');
        setInitialPressure('');
        setFinalPressure('');
        setDeltaDensity('');
        setCompressibility(null);
        setProvidedCompressibility('');
        setInitialVolume('');
        setDeltaVolume(null);
        setError('');
    };

    return (
        <Container>
            <h2 className="mt-4">Drilling Fluid Compressibility Estimator</h2>

            <Row className="mt-3">
                <Col md={6}>
                    <Form.Group controlId="fluidType" className="mb-3">
                        <Form.Label>Fluid Type:</Form.Label>
                        <Form.Control
                            as="select"
                            value={fluidType}
                            onChange={(e) => setFluidType(e.target.value)}
                        >
                            <option value="water-based">Water-Based</option>
                            <option value="oil-based">Oil-Based</option>
                            <option value="synthetic-based">Synthetic-Based</option>
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col md={6}>
                    <Form.Group controlId="initialDensity" className="mb-3">
                        <Form.Label>Initial Density (lb/ft³):</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Enter initial density"
                            value={initialDensity}
                            onChange={(e) => setInitialDensity(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group controlId="initialPressure" className="mb-3">
                        <Form.Label>Initial Pressure (psi):</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Enter initial pressure"
                            value={initialPressure}
                            onChange={(e) => setInitialPressure(e.target.value)}
                        />
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col md={6}>
                    <Form.Group controlId="finalPressure" className="mb-3">
                        <Form.Label>Final Pressure (psi):</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Enter final pressure"
                            value={finalPressure}
                            onChange={(e) => setFinalPressure(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group controlId="deltaDensity" className="mb-3">
                        <Form.Label>Change in Density (lb/ft³):</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Enter change in density"
                            value={deltaDensity}
                            onChange={(e) => setDeltaDensity(e.target.value)}
                        />
                    </Form.Group>
                </Col>
            </Row>

            <Row className="mt-4">
                <Col md={6} className="d-flex align-items-end">
                    <Button variant="primary" onClick={calculateCompressibility}>
                        Calculate Compressibility
                    </Button>
                </Col>
            </Row>

            <hr />

            <h3 className="mt-4">Volume Change Estimation</h3>
            <Row>
                <Col md={6}>
                    <Form.Group controlId="providedCompressibility" className="mb-3">
                        <Form.Label>Compressibility (psi⁻¹):</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Enter compressibility"
                            value={providedCompressibility}
                            onChange={(e) => setProvidedCompressibility(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group controlId="initialVolume" className="mb-3">
                        <Form.Label>Initial Volume (bbl):</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Enter initial volume"
                            value={initialVolume}
                            onChange={(e) => setInitialVolume(e.target.value)}
                        />
                    </Form.Group>
                </Col>
            </Row>

            <Row className="mt-4">
                <Col md={6} className="d-flex align-items-end">
                    <Button variant="primary" onClick={calculateVolumeChange}>
                        Calculate Volume Change
                    </Button>
                    <Button variant="secondary" className="ms-2" onClick={handleReset}>
                        Reset
                    </Button>
                </Col>
            </Row>

            {error && (
                <Alert variant="danger" className="mt-3">
                    {error}
                </Alert>
            )}

            {compressibility !== null && (
                <Row className="mt-4">
                    <Col>
                        <h3>Compressibility Result:</h3>
                        <Table striped bordered hover>
                            <tbody>
                                <tr>
                                    <td>Fluid Type:</td>
                                    <td>{fluidType}</td>
                                </tr>
                                <tr>
                                    <td>Compressibility (psi⁻¹):</td>
                                    <td>{compressibility.toExponential(4)}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            )}

            {deltaVolume !== null && (
                <Row className="mt-4">
                    <Col>
                        <h3>Volume Change Result:</h3>
                        <Table striped bordered hover>
                            <tbody>
                                <tr>
                                    <td>Change in Volume (bbl):</td>
                                    <td>{deltaVolume.toFixed(4)}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            )}
        </Container>
    );
}
