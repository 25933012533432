import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Modal, Button, Form, Row, Col, Container, Alert } from "react-bootstrap";
import { GlobalStateContext } from '../../GlobalStateContext';
import HelpComponent from '../../help/helpComponent';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import AddNewDrillBitForm from './addNewDrillBitForm';

function AddBitForm({ show, handleClose, wellId, wellboreId, wellDesignCaseId, onBitAdded }) {
    const { selectedItems } = useContext(GlobalStateContext);
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables

    const [bitTypes, setBitTypes] = useState([]);
    const [bitManufacturers, setBitManufacturers] = useState([]);
    const [bitIdentifications, setBitIdentifications] = useState([]);
    const [bitSizes, setBitSizes] = useState([]);
    const [iadcCodes, setIadcCodes] = useState([]);
    const [formData, setFormData] = useState({
        bit_type: '',
        bit_manufacturer: '',
        bit_identification: '',
        bit_size: '',
        iadc_code: '',
        nozzle_size1: '',
        no_of_nozzles1: '',
        nozzle_size2: '',
        no_of_nozzles2: ''
    });
    const [uniqueBitId, setUniqueBitId] = useState(null);
    const [showHelp, setShowHelp] = useState(false); // State for showing help modal
    const [setShowSuccess] = useState(false);
    const [showNewBitSuccess, setShowNewBitSuccess] = useState(false);

    const [showAddDrillBitModal, setShowAddDrillBitModal] = useState(false);
    // const openHoleParameters = selectedItems.openHoleParameters;

    const handleOpenAddDrillBitModal = () => setShowAddDrillBitModal(true);
    const handleCloseAddDrillBitModal = () => setShowAddDrillBitModal(false);
    const handleNewDrillBitAdded = () => {
        // You can add any logic here to update the state or refresh data when a new drill pipe is added
        setShowNewBitSuccess(true);
        console.log('New drill pipe added!');
    };

    useEffect(() => {
        axios.get(`${baseURL}/input/bit/types`, {
            withCredentials: true
        })
            .then(response => {
                setBitTypes(response.data);
            })
            .catch(error => {
                console.error('Error fetching bit types:', error);
            });
    }, [baseURL]);

    const handleBitTypeChange = (e) => {
        const bitType = e.target.value;
        setFormData({ ...formData, bit_type: bitType });

        axios.get(`${baseURL}/input/bit/manufacturers/${bitType}`, {
            withCredentials: true
        })
            .then(response => {
                setBitManufacturers(response.data);
                setBitIdentifications([]); // Clear bit identifications when bit type changes
            })
            .catch(error => {
                console.error('Error fetching bit manufacturers:', error);
            });
    };

    const handleBitManufacturerChange = (e) => {
        const bitManufacturerId = e.target.value;
        setFormData({ ...formData, bit_manufacturer: bitManufacturerId });

        axios.get(`${baseURL}/input/bit/identifications/${bitManufacturerId}/${formData.bit_type}`, {
            withCredentials: true
        })
            .then(response => {
                setBitIdentifications(response.data);
            })
            .catch(error => {
                console.error('Error fetching bit identifications:', error);
            });
    };

    const handleBitIdentificationChange = (e) => {
        const bitIdentification = e.target.value;
        setFormData({ ...formData, bit_identification: bitIdentification });

        // Fetch IADC code
        axios.get(`${baseURL}/input/bit/iadc_code/${bitIdentification}`, {
            withCredentials: true
        })
            .then(response => {
                setIadcCodes([response.data]); // Assuming IADC code is unique for each bit identification
                const iadcCode = response.data;
                console.log('IADC Codes', response.data);

                // Fetch bit sizes using both bit_identification and iadc_code
                axios.get(`${baseURL}/input/bit/sizes/${bitIdentification}/${iadcCode}`, {
                    withCredentials: true
                })
                    .then(response => {
                        setBitSizes(response.data);
                    })
                    .catch(error => {
                        console.error('Error fetching bit sizes:', error);
                    });

                setFormData(prevFormData => ({
                    ...prevFormData,
                    iadc_code: iadcCode
                }));
            })
            .catch(error => {
                console.error('Error fetching IADC code:', error);
            });
    };

    const handleBitSizeChange = (e) => {
        const bitSize = e.target.value;
        setFormData({ ...formData, bit_size: bitSize });

        // Fetch unique bit ID
        axios.get(`${baseURL}/input/bit_catalogue/${formData.bit_type}/${formData.bit_manufacturer}/${formData.bit_identification}/${bitSize}/${formData.iadc_code}`, {
            withCredentials: true
        })
            .then(response => {
                const uniqueBitId = response.data.unique_drill_bit_id;
                setUniqueBitId(uniqueBitId);
                console.log('Unique Bit ID', uniqueBitId);
                setFormData(prevFormData => ({
                    ...prevFormData,
                    unique_drill_bit_id: uniqueBitId
                }));
            })
            .catch(error => {
                console.error('Error fetching unique bit ID:', error);
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { bit_type, bit_manufacturer, bit_identification, bit_size, iadc_code, ...dataWithoutId } = formData;

        const newBitData = {
            ...dataWithoutId,
            nozzle_size1: parseInt(dataWithoutId.nozzle_size1),
            no_of_nozzles1: parseInt(dataWithoutId.no_of_nozzles1),
            nozzle_size2: parseInt(dataWithoutId.nozzle_size2),
            no_of_nozzles2: parseInt(dataWithoutId.no_of_nozzles2),
            well_id: parseInt(wellId),
            wellbore_id: parseInt(wellboreId),
            well_design_case_id: parseInt(wellDesignCaseId),
            unique_drill_bit_id: parseInt(uniqueBitId)
        };

        axios.post(`${baseURL}/input/bits`, newBitData, {
            withCredentials: true
        })
            .then(response => {
                onBitAdded(response.data);
                setShowSuccess(true);
            })
            .catch(error => {
                console.error('Error adding new bit:', error);
            });
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add a drill bit for {selectedItems.well_design_case_name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-3">
                            <Button variant="outline-secondary" onClick={() => setShowHelp(true)} style={{ marginLeft: '10px' }}>
                                Help
                            </Button>
                        </div>
                    </Row>
                    <Row>
                        {/* {showSuccess && (
                            <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible>
                                You have successfully added a new drill bit to the current design case.
                                It should now be available for selection in the Bit table.
                                Please close this dialog box to return to the drill bit table.
                            </Alert>
                        )} */}
                        {showNewBitSuccess && (
                            <Alert variant="success" onClose={() => setShowNewBitSuccess(false)} dismissible>
                                You have successfully added a new drill bit to the database.
                                It should now be available for selection in the Add Bit form below.
                            </Alert>
                        )}
                    </Row>
                    <Row>
                        <div>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group as={Row} controlId="bitType">
                                    <Form.Label column sm={4}>Bit Type</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control as="select" value={formData.bit_type} onChange={handleBitTypeChange} required>
                                            <option value="">Select Bit Type</option>
                                            {bitTypes.map((type, index) => (
                                                <option key={index} value={type}>{type}</option>
                                            ))}
                                        </Form.Control>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="bitManufacturer">
                                    <Form.Label column sm={4}>Bit Manufacturer</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control as="select" value={formData.bit_manufacturer} onChange={handleBitManufacturerChange} required>
                                            <option value="">Select Bit Manufacturer</option>
                                            {bitManufacturers.map(manufacturer => (
                                                <option key={manufacturer.bit_manufacturer_id} value={manufacturer.bit_manufacturer_id}>{manufacturer.bit_manufacturer}</option>
                                            ))}
                                        </Form.Control>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="bitIdentification">
                                    <Form.Label column sm={4}>Bit Identification</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control as="select" value={formData.bit_identification} onChange={handleBitIdentificationChange} required>
                                            <option value="">Select Bit Identification</option>
                                            {bitIdentifications.map(bit => (
                                                <option key={bit} value={bit}>{bit}</option>
                                            ))}
                                        </Form.Control>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="iadcCode">
                                    <Form.Label column sm={4}>IADC Code</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control as="select" value={formData.iadc_code} disabled>
                                            <option value="">Select IADC Code</option>
                                            {iadcCodes.map((code, index) => (
                                                <option key={index} value={code}>{code}</option>
                                            ))}
                                        </Form.Control>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="bitSize">
                                    <Form.Label column sm={4}>Bit Size</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control as="select" value={formData.bit_size} onChange={handleBitSizeChange} required>
                                            <option value="">Select Bit Size</option>
                                            {bitSizes.map((size, index) => (
                                                <option key={index} value={size}>{size}</option>
                                            ))}
                                        </Form.Control>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="nozzleSize1">
                                    <Form.Label column sm={4}>Jet Size 1</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control type="number" name="nozzle_size1" value={formData.nozzle_size1} onChange={(e) => setFormData({ ...formData, nozzle_size1: e.target.value })} required />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="noOfNozzles1">
                                    <Form.Label column sm={4}>No of Jets 1</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control type="number" name="no_of_nozzles1" value={formData.no_of_nozzles1} onChange={(e) => setFormData({ ...formData, no_of_nozzles1: e.target.value })} required />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="nozzleSize2">
                                    <Form.Label column sm={4}>Jet Size 2</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control type="number" name="nozzle_size2" value={formData.nozzle_size2} onChange={(e) => setFormData({ ...formData, nozzle_size2: e.target.value })} />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="noOfNozzles2">
                                    <Form.Label column sm={4}>No of Jets 2</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control type="number" name="no_of_nozzles2" value={formData.no_of_nozzles2} onChange={(e) => setFormData({ ...formData, no_of_nozzles2: e.target.value })} />
                                    </Col>
                                </Form.Group>

                                <Button variant="primary" type="submit" style={{ marginTop: '20px' }}>
                                    Add Bit
                                </Button>

                                <div className='mt-2'>
                                    Can't find the right drill bit?{' '}
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="add-to-db-tooltip">Click to add a new bit to the database</Tooltip>}
                                    >
                                        <button
                                            type="button"
                                            className="text-decoration-none btn btn-link p-0"
                                            onClick={handleOpenAddDrillBitModal}
                                        >
                                            Add it to the database.
                                        </button>
                                    </OverlayTrigger>

                                    <AddNewDrillBitForm
                                        show={showAddDrillBitModal}
                                        handleClose={handleCloseAddDrillBitModal}
                                        onNewBitAdded={handleNewDrillBitAdded}
                                    />
                                </div>

                            </Form>
                        </div>
                    </Row>
                </Container>
            </Modal.Body>
            <HelpComponent show={showHelp} handleClose={() => setShowHelp(false)} section="addBitForm" />
        </Modal>
    );
}

export default AddBitForm;
