import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { GlobalStateContext } from '../../GlobalStateContext';
import axios from 'axios';

function EditBhaForm({ show, handleClose, bhaItem, onBhaItemUpdated }) {
    const { selectedItems } = useContext(GlobalStateContext);
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables

    const bhaTypes = ['Drill pipe', 'HWDP', 'Drill collar', 'Jar', 'Casing', 'Tubing', 'Motor'];
    // const [bhaType, setbhaTypes] = useState([]);

    const dpClass = ['New', 'Premium', 'C-1', 'C-2', 'C-3'];
    const dpRange = ['1', '2', '3'];

    const [od, setOd] = useState([]);
    const [nomWeights, setNomWeights] = useState([]);
    // const [dpInfo, setDpInfo] = useState({});
    const [dpConn, setDpConn] = useState([]);
    const [dpGrade, setDpGrade] = useState([]);

    const [formData, setFormData] = useState({
        well_design_case_id: '',
        wellbore_id: '',
        well_id: '',
        type: '',
        dp_size: '',
        dp_nom_wt: '',
        dp_id: '',
        tool_length: '',
        dp_grade: '',
        dp_conn: '',
        tool_class: '',
        tool_jt_od: '',
        tool_jt_id: '',
        range_length: ''
    });

    const fetchOdValues = (bhaType) => {
        axios.get(`${baseURL}/input/dp_library/unique_od?bha_type=${bhaType}`, {
            withCredentials: true
        })
            .then(response => {
                const processedOdValues = response.data.od_values.map(od => {
                    od = od.toString();
                    if (!od.includes('.')) {
                        return parseFloat(od).toFixed(1);
                    }
                    return od;
                });
                setOd(processedOdValues);
            })
            .catch(error => {
                console.error('Error fetching unique drill pipe ODs:', error);
            });
    };

    const fetchNomWeights = (dpSize) => {
        axios.get(`${baseURL}/input/dp_library/${dpSize}`, {
            withCredentials: true
        })
            .then(response => {
                setNomWeights(response.data);
            })
            .catch(error => {
                console.error('Error fetching dp nom wt:', error);
            });
    };

    const fetchDpInfo = (dpSize, dpNomWt) => {
        axios.get(`${baseURL}/input/dp_library/${dpSize}/${dpNomWt}`, {
            withCredentials: true
        })
            .then(response => {
                // setDpInfo(response.data);
                setDpConn(response.data.dp_conn);
                setDpGrade(response.data.dp_grade);
            })
            .catch(error => {
                console.error('Error fetching DP Info:', error);
            });
    };

    const fetchDpConn = (dpSize, dpNomWt) => {
        axios.get(`${baseURL}/input/dp_library/${dpSize}/${dpNomWt}`, {
            withCredentials: true
        })
            .then(response => {
                setDpConn(response.data.dp_conn);
            })
            .catch(error => {
                console.error('Error fetching DP Conn:', error);
            });
    };

    const dpSize = bhaItem?.dp_size;
    const dpNomWt = bhaItem?.dp_nom_wt;
    const bhaType = bhaItem?.type;

    useEffect(() => {

        if (bhaItem) {
            setFormData({
                well_design_case_id: selectedItems.well_design_case_id || '',
                wellbore_id: selectedItems.wellbore_id || '',
                well_id: selectedItems.well_id || '',
                type: bhaItem.type || '',
                dp_size: bhaItem.dp_size || '',
                dp_nom_wt: bhaItem.dp_nom_wt || '',
                dp_id: bhaItem.dp_id || '',
                tool_length: bhaItem.tool_length || '',
                dp_grade: bhaItem.dp_grade || '',
                dp_conn: bhaItem.dp_conn || '',
                tool_class: bhaItem.tool_class || '',
                tool_jt_od: bhaItem.tool_jt_od || '',
                tool_jt_id: bhaItem.tool_jt_id || '',
                range_length: bhaItem.range_length || ''
            });

            if (bhaItem.type) {
                console.log('bha type', bhaItem.type);
                // fetchOdValues(bhaItem.type);

                axios.get(`${baseURL}/input/dp_library/unique_od?bha_type=${bhaType}`, {
                    withCredentials: true
                })
                    .then(response => {
                        const processedOdValues = response.data.od_values.map(od => {
                            od = od.toString();
                            if (!od.includes('.')) {
                                return parseFloat(od).toFixed(1);
                            }
                            return od;
                        });
                        setOd(processedOdValues);
                    })
                    .catch(error => {
                        console.error('Error fetching unique drill pipe ODs:', error);
                    });
            }

            if (bhaItem.dp_size) {
                // fetchNomWeights(bhaItem.dp_size);

                axios.get(`${baseURL}/input/dp_library/${dpSize}`, {
                    withCredentials: true
                })
                    .then(response => {
                        setNomWeights(response.data);
                    })
                    .catch(error => {
                        console.error('Error fetching dp nom wt:', error);
                    });
            }

            if (bhaItem.dp_size && bhaItem.dp_nom_wt) {
                // fetchDpInfo(bhaItem.dp_size, bhaItem.dp_nom_wt);

                axios.get(`${baseURL}/input/dp_library/${dpSize}/${dpNomWt}`, {
                    withCredentials: true
                })
                    .then(response => {
                        // setDpInfo(response.data);
                        setDpConn(response.data.dp_conn);
                        setDpGrade(response.data.dp_grade);
                    })
                    .catch(error => {
                        console.error('Error fetching DP Info:', error);
                    });

            }

            if (bhaItem.dp_conn) {
                // fetchDpConn(bhaItem.dp_size, bhaItem.dp_nom_wt);

                axios.get(`${baseURL}/input/dp_library/${dpSize}/${dpNomWt}`, {
                    withCredentials: true
                })
                    .then(response => {
                        setDpConn(response.data.dp_conn);
                    })
                    .catch(error => {
                        console.error('Error fetching DP Conn:', error);
                    });
            }
        }
    }, [bhaItem, selectedItems.well_design_case_id, selectedItems.well_id, selectedItems.wellbore_id, baseURL, bhaType, dpNomWt, dpSize]);

    const handleBhaTypeChange = (e) => {
        const bhaType = e.target.value;
        setFormData({ ...formData, type: bhaType });
        fetchOdValues(bhaType);
    };

    const handleOdChange = (e) => {
        const dpSize = e.target.value;
        setFormData({ ...formData, dp_size: dpSize });
        fetchNomWeights(dpSize);
    };

    const handleNomWtChange = (e) => {
        const dpNomWt = e.target.value;
        setFormData({ ...formData, dp_nom_wt: dpNomWt });
        fetchDpInfo(formData.dp_size, dpNomWt);
    };

    const handleConnChange = (e) => {
        const dpConn = e.target.value;
        setFormData({ ...formData, dp_conn: dpConn });
        fetchDpConn(formData.dp_size, formData.dp_nom_wt);
    };

    const handleGradeChange = (e) => {
        const dpGrade = e.target.value;
        setFormData(prevState => ({ ...prevState, dp_grade: dpGrade }));

        if (formData.dp_conn) {
            handleConnChange({ target: { value: formData.dp_conn } });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios({
            method: "PUT",
            url: `${baseURL}/input/bha_items/${bhaItem.bha_item_id}`,
            withCredentials: true,
            data: formData
        })
            .then(response => {
                if (response.status === 200) {
                    onBhaItemUpdated();
                    handleClose();
                }
            })
            .catch(error => {
                if (error.response) {
                    console.log(error.response);
                }
            });
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit BHA Item</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group as={Row} controlId="bhaType">
                        <Form.Label column sm={4}>BHA item type</Form.Label>
                        <Col sm={8}>
                            <Form.Control as="select" value={formData.type} onChange={handleBhaTypeChange} required>
                                <option value="">Select item type</option>
                                {bhaTypes.map((value, index) => (
                                    <option key={index} value={value}>{value}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="od">
                        <Form.Label column sm={4}>Outer Diameter</Form.Label>
                        <Col sm={8}>
                            <Form.Control as="select" value={formData.dp_size} onChange={handleOdChange} required>
                                <option value="">Select OD</option>
                                {od.map((value, index) => (
                                    <option key={index} value={value}>{value}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="nomWt">
                        <Form.Label column sm={4}>Nominal Weight</Form.Label>
                        <Col sm={8}>
                            <Form.Control as="select" value={formData.dp_nom_wt} onChange={handleNomWtChange} required>
                                <option value="">Select Nom. Wt.</option>
                                {nomWeights.map((nomWt, index) => (
                                    <option key={index} value={nomWt.dp_nom_wt}>{nomWt.dp_nom_wt}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="dpId">
                        <Form.Label column sm={4}>Internal Diameter</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                name="dp_id"
                                value={formData.dp_id}
                                readOnly
                                disabled
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="dpGrade">
                        <Form.Label column sm={4}>Grade</Form.Label>
                        <Col sm={8}>
                            <Form.Control as="select" value={formData.dp_grade} onChange={handleGradeChange} required>
                                <option value="">Select Grade</option>
                                {dpGrade && dpGrade.length > 0 && dpGrade.map((grade, index) => (
                                    <option key={index} value={grade}>{grade}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="connection">
                        <Form.Label column sm={4}>Connection</Form.Label>
                        <Col sm={8}>
                            <Form.Control as="select" value={formData.dp_conn} onChange={handleConnChange} required>
                                <option value="">Select Connection</option>
                                {dpConn.map((connection, index) => (
                                    <option key={index} value={connection}>{connection}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="class">
                        <Form.Label column sm={4}>Class</Form.Label>
                        <Col sm={8}>
                            <Form.Control as="select" value={formData.tool_class} onChange={(e) => setFormData({ ...formData, tool_class: e.target.value })} required>
                                <option value="">Select Class</option>
                                {dpClass.map((value, index) => (
                                    <option key={index} value={value}>{value}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="dpTjOd">
                        <Form.Label column sm={4}>Tool Joint OD</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="text"
                                name="dp_tj_od"
                                value={formData.tool_jt_od}
                                readOnly
                                disabled
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="dpTjId">
                        <Form.Label column sm={4}>Tool Joint ID</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="text"
                                name="dp_tj_id"
                                value={formData.tool_jt_id}
                                readOnly
                                disabled
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="dpRange">
                        <Form.Label column sm={4}>Range</Form.Label>
                        <Col sm={8}>
                            <Form.Control as="select" value={formData.range_length} onChange={(e) => setFormData({ ...formData, range_length: e.target.value })} required>
                                <option value="">Select Range</option>
                                {dpRange.map((value, index) => (
                                    <option key={index} value={value}>{value}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="length">
                        <Form.Label column sm={4}>Length</Form.Label>
                        <Col sm={8}>
                            <Form.Control type="number" name="tool_length" value={formData.tool_length} onChange={handleInputChange} required />
                        </Col>
                    </Form.Group>

                    <Button variant="primary" type="submit" style={{ marginTop: '20px' }}>
                        Save
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default EditBhaForm;
