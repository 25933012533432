import React, { useState } from "react";
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import axios from 'axios';

export default function ForgotPasswordModal({ show, handleClose, baseURL }) {
    const [email, setEmail] = useState('');
    const [emailAlert, setEmailAlert] = useState(false);
    const [message, setMessage] = useState('');

    const handlePasswordReset = () => {
        console.log('modal clicked');

        if (email.length === 0) {
            setEmailAlert(true);
            return;
        }
        setEmailAlert(false);

        axios.post(`${baseURL}/request_password_reset`, { email })
            .then(response => {
                console.log('response', response)
                setMessage('A password reset link has been sent to your email.');
            })
            .catch(error => {
                setMessage('An error occurred. Please try again later.');
            });
    };

    // console.log('forgot password clicked?');

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Forgot Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="forgotPasswordEmail">
                        <Form.Label>Enter your email address</Form.Label>
                        <Form.Control
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter your email"
                        />
                        {emailAlert && <Alert variant="danger">Email cannot be empty.</Alert>}
                    </Form.Group>
                    {message && <Alert variant="info">{message}</Alert>}
                    <Button variant="primary" onClick={handlePasswordReset}>
                        Send Reset Link
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
