import "./dashboard.scss";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import { useTranslate } from 'react-admin'; // Optional for translation
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useAuth } from "../../components/admin/AuthContext";

const Dashboard = () => {
    const [userStats, setUserStats] = useState(null);
    const [designStats, setDesignStats] = useState(null);
    const translate = useTranslate();
    const apiUrl = process.env.REACT_APP_ADMIN_API_BASE_URL; // Get base URL from environment variables
    const { isAdmin, isAuthenticated } = useAuth();

    useEffect(() => {
        const fetchUserStats = async () => {
            try {
                const response = await axios.get(`${apiUrl}/stats/users`, {
                    withCredentials: true,
                });
                setUserStats(response.data);
            } catch (error) {
                console.error('Error fetching userStats:', error);
            }
        };

        if (isAuthenticated && isAdmin) {
            fetchUserStats();
        }
    }, [apiUrl, isAuthenticated, isAdmin]);

    useEffect(() => {
        const fetchDesignStats = async () => {
            try {
                const response = await axios.get(`${apiUrl}/stats/design_cases`, {
                    withCredentials: true,
                });
                setDesignStats(response.data);
                console.log('designStats', response.data);
            } catch (error) {
                console.error('Error fetching designStats:', error);
            }
        };

        if (isAuthenticated && isAdmin) {
            fetchDesignStats();
        }
    }, [apiUrl, isAuthenticated, isAdmin]);

    if (!isAuthenticated) {
        return <div>Please log in to view the dashboard.</div>;
    }

    if (!isAdmin) {
        return <div>You do not have access to view this page.</div>;
    }

    if (!userStats || !designStats) {
        return <div>Loading...</div>;
    }

    const userChartData = userStats.users_by_month.map(entry => ({
        name: entry.month,
        users: entry.count,
    }));

    return (
        <Grid container spacing={3}>
            {/* Total Users */}
            <Grid item xs={12} sm={6} md={4}>
                <Card>
                    <CardContent>
                        <Typography variant="h6">{translate('Total Users')}</Typography>
                        <Typography variant="h4">{userStats.total_users}</Typography>
                    </CardContent>
                </Card>
            </Grid>

            {/* New Users Last 30 Days */}
            <Grid item xs={12} sm={6} md={4}>
                <Card>
                    <CardContent>
                        <Typography variant="h6">{translate('New Users (Last 30 Days)')}</Typography>
                        <Typography variant="h4">{userStats.new_users_last_30_days}</Typography>
                    </CardContent>
                </Card>
            </Grid>

            {/* Total Designs */}
            <Grid item xs={12} sm={6} md={4}>
                <Card>
                    <CardContent>
                        <Typography variant="h6">{translate('Total Designs')}</Typography>
                        <Typography variant="h4">{designStats.total_designs}</Typography>
                    </CardContent>
                </Card>
            </Grid>

            {/* New Designs Last 30 Days */}
            <Grid item xs={12} sm={6} md={4}>
                <Card>
                    <CardContent>
                        <Typography variant="h6">{translate('New Designs (Last 30 Days)')}</Typography>
                        <Typography variant="h4">{designStats.new_designs_last_30_days}</Typography>
                    </CardContent>
                </Card>
            </Grid>

            {/* Users by Month Chart */}
            <Grid item xs={12} sm={6} md={4}>
                <Card>
                    <CardContent>
                        <Typography variant="h6">{translate('Users by Month')}</Typography>
                        <ResponsiveContainer width="100%" height={300}>
                            <LineChart data={userChartData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="users" stroke="#8884d8" />
                            </LineChart>
                        </ResponsiveContainer>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default Dashboard;
