import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

function NotFound() {

    return (
        <Container className="text-center mt-5 vh-100">
            <Row>
                <Col>
                    <h1>404 - Page Not Found</h1>
                    <p>Oops! The page you're looking for doesn't exist.</p>
                    <Link to="/" className="btn btn-primary">Go Home</Link>
                </Col>
            </Row>
            
        </Container>
    );
}

export default NotFound;
