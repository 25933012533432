import React from 'react';

import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import { useHoleSection } from '../../components/HoleSectionProvider';

function SidebarInputs() {
    const { holeSection } = useHoleSection();

    return (

        <Nav defaultActiveKey="/design_case" className="flex-column">
            <Nav.Link as={Link} to="/design_case">Design case</Nav.Link>
            <Nav.Link
                as={Link}
                to={holeSection.length > 0 ? "/well_information" : "#"}
                disabled={!holeSection.length > 0}
            >
                Well information
            </Nav.Link>

            {/* <Nav.Link eventKey="/design_case">Design case</Nav.Link>
            <Nav.Link eventKey="/well_information">Well information</Nav.Link> */}

        </Nav>
    );
}

export default SidebarInputs;