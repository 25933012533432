import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import AddRigEquipmentForm from "./addRigEquipmentForm";
import EditRigEquipmentModal from "./editRigEquipmentModal";
import { GlobalStateContext } from "../../GlobalStateContext";

export default function RigEquipmentTable(props) {
    const { selectedItems, setSelectedItems } = useContext(GlobalStateContext);

    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables

    const [rigEquipmentData, setRigEquipmentData] = useState([]);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [equipmentIdToDelete, setEquipmentIdToDelete] = useState(null);
    const [showAddEquipmentModal, setShowAddEquipmentModal] = useState(false);
    const [equipmetToEdit, setEquipmetToEdit] = useState(null);
    const [showEditEquipmentModal, setShowEditEquipmentModal] = useState(false);

    useEffect(() => {
        fetchRigEquipmentDatabyWellWellbore();
    }, [selectedItems.well_id, selectedItems.wellbore_id, selectedItems.well_design_case_id]);

    function fetchRigEquipmentDatabyWellWellbore() {
        axios.get(`${baseURL}/input/rig_equipment/${selectedItems.well_id}/${selectedItems.wellbore_id}/${selectedItems.well_design_case_id}`, {
            withCredentials: true
        })
            .then((response) => {
                const equipmentData = response.data;
                setRigEquipmentData(equipmentData);

                console.log('equipmentData', equipmentData);

                // Calculate the cumulative rate
                const cumRate = equipmentData.reduce((acc, equipment, index) => {
                    const pumpRate = 0.000243 * equipment.liner_size ** 2 * equipment.stroke_len * equipment.pump_spm * 42 * equipment.pump_eff;
                    return acc + pumpRate;
                }, 0).toFixed(2);

                // Update the global state
                setSelectedItems(prevState => ({
                    ...prevState,
                    cum_rate: cumRate,
                    equipmentParameters: equipmentData,
                }));
            })
            .catch((error) => {
                console.error("Error fetching rig equipment:", error);
            });
    }

    const handleEquipmentAdded = () => {
        fetchRigEquipmentDatabyWellWellbore();
    };

    const deleteEquipment = () => {
        setShowConfirmationModal(false); // Close the modal

        axios.delete(`${baseURL}/input/rig_equipment/${equipmentIdToDelete}`, {
            withCredentials: true
        })
            .then(function (response) {
                fetchRigEquipmentDatabyWellWellbore();
            })
            .catch(function (error) {
                console.error('Error deleting rig equipment:', error);
            });

        alert("Successfully Deleted");
    };

    const handleEditEquipment = (equipment) => {
        setEquipmetToEdit(equipment);
        setShowEditEquipmentModal(true);
    };

    const handleEquipmentUpdated = (updatedBit) => {
        fetchRigEquipmentDatabyWellWellbore();
        setShowEditEquipmentModal(false);
    };

    return (
        <div>
            <div className="container h-0">
                <div className="row h-20">
                    <div className="table-responsive small">
                        <table className="table table-striped">
                            <thead className="border-top border-primary">
                                <tr>
                                    <th className="fw-normal">Class</th>
                                    <th className="fw-normal">Pump</th>
                                    <th className="fw-normal">Liner size</th>
                                    <th className="fw-normal">Stroke</th>
                                    <th className="fw-normal">Pump SPM</th>
                                    <th className="fw-normal">Pump eff</th>
                                    <th className="fw-normal">Pump Rate</th>
                                    <th className="fw-normal">Cum Rate</th>
                                    <th className="fw-normal">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rigEquipmentData.map((rig_equipment, index) => {
                                    const pumpRate = parseFloat((0.000243 * rig_equipment.liner_size ** 2 * rig_equipment.stroke_len * rig_equipment.pump_spm * 42 * rig_equipment.pump_eff).toFixed(2));
                                    const cumRate = parseFloat(rigEquipmentData.slice(0, index + 1).reduce((acc, equipment) => {
                                        return acc + (0.000243 * equipment.liner_size ** 2 * equipment.stroke_len * equipment.pump_spm * 42 * equipment.pump_eff);
                                    }, 0).toFixed(2));

                                    return (
                                        <tr key={index}>
                                            <td>{rig_equipment.surf_equip_class}</td>
                                            <td>{rig_equipment.pump_num}</td>
                                            <td>{rig_equipment.liner_size}</td>
                                            <td>{rig_equipment.stroke_len}</td>
                                            <td>{rig_equipment.pump_spm}</td>
                                            <td>{rig_equipment.pump_eff}</td>
                                            <td>{pumpRate}</td>
                                            <td>{cumRate}</td>
                                            <td>
                                                <Button href="#"
                                                    variant="outline-primary"
                                                    className="rounded-circle"
                                                    size="sm"
                                                    onClick={() => handleEditEquipment(rig_equipment)}>
                                                    <i className="bi bi-pencil"></i>
                                                </Button>
                                                <Button
                                                    variant="outline-danger"
                                                    className="rounded-circle"
                                                    size="sm"
                                                    onClick={() => {
                                                        setEquipmentIdToDelete(rig_equipment.rig_equip_id);
                                                        setShowConfirmationModal(true);
                                                    }}>
                                                    <i className="bi bi-trash"></i>
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>

                        <Button
                            variant="primary"
                            onClick={() => { setShowAddEquipmentModal(true); }}
                            style={{ marginTop: '20px' }}
                        >
                            Add Rig Equipment
                        </Button>

                        <AddRigEquipmentForm
                            show={showAddEquipmentModal}
                            handleClose={() => setShowAddEquipmentModal(false)}
                            wellId={props.wellId}
                            wellboreId={props.wellboreId}
                            wellDesignCaseId={props.wellDesignCaseId}
                            onEquipmentAdded={handleEquipmentAdded}
                        />

                    </div>
                </div>
            </div>

            <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this pump?</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowConfirmationModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={deleteEquipment}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            {equipmetToEdit && (
                <EditRigEquipmentModal
                    show={showEditEquipmentModal}
                    handleClose={() => setShowEditEquipmentModal(false)}
                    equipment={equipmetToEdit}
                    onEquipmentUpdated={handleEquipmentUpdated}
                />
            )}
        </div>
    );
}
