import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Button, Alert } from 'react-bootstrap';
import axios from 'axios';

export default function PasswordResetForm() {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const { token } = useParams();
    const navigate = useNavigate(); // Use useNavigate instead of useHistory
    const [recaptchaToken, setRecaptchaToken] = useState(null); // State to store reCAPTCHA token
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables

    // const handleRecaptcha = async () => {
    //     // Call reCAPTCHA to get the token
    //     const token = await window.grecaptcha.execute('6Lf5a2YqAAAAALnPS6lOy-suZsukdDWL6UpWRURd', { action: 'reset_password' });
    //     setRecaptchaToken(token); // Store the reCAPTCHA token
    // };

    const handleRecaptcha = async () => {
        // Get the reCAPTCHA site key from the environment variable
        const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

        // Call reCAPTCHA to get the token
        const token = await window.grecaptcha.execute(recaptchaSiteKey, { action: 'reset_password' });
        setRecaptchaToken(token); // Store the reCAPTCHA token
    };


    useEffect(() => {
        handleRecaptcha(); // Automatically call reCAPTCHA when the component mounts
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccessMessage('');

        if (newPassword !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        // Check if reCAPTCHA token is generated
        if (!recaptchaToken) {
            setError('Please complete the reCAPTCHA.');
            return;
        }

        try {
            const response = await axios.post(`${baseURL}/reset_password/${token}`, {
                new_password: newPassword,
                'g-recaptcha-response': recaptchaToken, // Include reCAPTCHA token
            });

            if (response.status === 200) {
                setSuccessMessage('Your password has been reset successfully. You can now log in.');
                // Redirect to login page after a short delay
                setTimeout(() => navigate('/login'), 3000);
            }
        } catch (error) {
            setError('Failed to reset password. Please try again.');
        }
    };

    return (
        <div className="login template d-flex justify-content-center align-items-center vh-100 bg-white">
            <div className="form_container p-5 rounded bg-light">

            <h3 className="text-center">Reset Your Password</h3>
            {error && <Alert variant="danger">{error}</Alert>}
            {successMessage && <Alert variant="success">{successMessage}</Alert>}

            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formNewPassword" className="mb-3">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Enter new password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="formConfirmPassword" className="mb-3">
                    <Form.Label>Confirm New Password</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Confirm new password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                </Form.Group>

                <Button variant="primary" type="submit" className="w-100">
                    Reset Password
                </Button>
                </Form>
            </div>
        </div>
    );
}
