import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import axios from 'axios';

function EditRheologyModal({ show, handleClose, rheologyData, onRheologyUpdated }) {
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables

    const [formData, setFormData] = useState({
        section_type_id: '',
        mud_type: '',
        mwt_rheology: '',
        pv: '',
        yp: '',
        fann3: '',
        fann6: '',
        fann300: '',
        fann600: '',
        cuttings_diam: ''
    });

    const [sectionTypeName, setSectionTypeName] = useState('');

    useEffect(() => {
        if (rheologyData) {
            setFormData({
                section_type_id: rheologyData.section_type_id || '',
                mud_type: rheologyData.mud_type || '',
                mwt_rheology: rheologyData.mwt_rheology || '',
                pv: rheologyData.pv || '',
                yp: rheologyData.yp || '',
                fann3: rheologyData.fann3 || '',
                fann6: rheologyData.fann6 || '',
                fann300: rheologyData.fann300 || '',
                fann600: rheologyData.fann600 || '',
                cuttings_diam: rheologyData.cuttings_diam || ''
            });

            fetchSectionTypeName(rheologyData.section_type_id);
        }
    }, [rheologyData]);

    const fetchSectionTypeName = (sectionTypeId) => {
        if (sectionTypeId) {
            axios.get(`${baseURL}/input/section_type/${sectionTypeId}`, {
                withCredentials: true
            })
                .then(response => {
                    const sectionTypeName = response.data.section_type || '';
                    setSectionTypeName(sectionTypeName);
                    console.log(`Fetched section type - ID: ${sectionTypeId}, Name: ${sectionTypeName}`);
                })
                .catch(error => {
                    console.error('Error fetching section type name:', error);
                });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const updatedRheology = {
            section_type_id: formData.section_type_id,
            mud_type: formData.mud_type,
            mwt_rheology: parseFloat(formData.mwt_rheology),
            pv: parseFloat(formData.pv),
            yp: parseFloat(formData.yp),
            fann3: parseFloat(formData.fann3),
            fann6: parseFloat(formData.fann6),
            fann300: parseFloat(formData.fann300),
            fann600: parseFloat(formData.fann600),
            cuttings_diam: parseFloat(formData.cuttings_diam)
        };

        axios.put(`${baseURL}/input/rheology/${rheologyData.rheology_id}`, updatedRheology, {
            withCredentials: true
        })
            .then(response => {
                onRheologyUpdated(response.data.rheology);
                handleClose();
            })
            .catch(error => {
                console.error('Error updating rheology:', error);
            });
    };

    if (!rheologyData) {
        return null;
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Rheology</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group as={Row} controlId="sectionType">
                        <Form.Label column sm={4}>Section Type</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="text"
                                name="section_type_name"
                                value={sectionTypeName || ''}
                                readOnly
                                disabled
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="mudType">
                        <Form.Label column sm={4}>Mud Type</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="text"
                                name="mud_type"
                                value={formData.mud_type || ''}
                                readOnly
                                disabled
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="mwtRheology">
                        <Form.Label column sm={4}>Mwt Rheology</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                step="any"
                                name="mwt_rheology"
                                value={formData.mwt_rheology || ''}
                                onChange={handleChange}
                                required
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="pv">
                        <Form.Label column sm={4}>PV</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                step="any"
                                name="pv"
                                value={formData.pv || ''}
                                onChange={handleChange}
                                required
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="yp">
                        <Form.Label column sm={4}>Yp</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                step="any"
                                name="yp"
                                value={formData.yp || ''}
                                onChange={handleChange}
                                required
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="fann3">
                        <Form.Label column sm={4}>3rpm</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                step="any"
                                name="fann3"
                                value={formData.fann3 || ''}
                                onChange={handleChange}
                                required
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="fann6">
                        <Form.Label column sm={4}>6rpm</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                step="any"
                                name="fann6"
                                value={formData.fann6 || ''}
                                onChange={handleChange}
                                required
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="fann300">
                        <Form.Label column sm={4}>300rpm</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                step="any"
                                name="fann300"
                                value={formData.fann300 || ''}
                                onChange={handleChange}
                                required
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="fann600">
                        <Form.Label column sm={4}>600rpm</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                step="any"
                                name="fann600"
                                value={formData.fann600 || ''}
                                onChange={handleChange}
                                required
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="cuttings">
                        <Form.Label column sm={4}>Cuttings Diameter</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                step="any"
                                name="cuttings_diam"
                                value={formData.cuttings_diam || ''}
                                onChange={handleChange}
                                required
                            />
                        </Col>
                    </Form.Group>
                    <Button variant="primary" type="submit" style={{ marginTop: '20px' }}>
                        Save Changes
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default EditRheologyModal;
