import React from 'react';
import { Container, Card } from 'react-bootstrap';

const PrivacyPolicy = () => {
    return (
        <Container className="my-5">
            <Card>
                <Card.Header>
                    <h2>Privacy Policy</h2>
                </Card.Header>
                <Card.Body>
                    <p>Your privacy is important to us. This policy outlines how we collect, use, and protect your information:</p>
                    <h4>1. Information We Collect</h4>
                    <p>We may collect data such as user inputs, usage statistics, and technical information related to the use of the app.</p>
                    <h4>2. Use of Information</h4>
                    <p>The data collected is used to improve the functionality of the app and provide better user experiences.</p>
                    <h4>3. Data Security</h4>
                    <p>We take measures to protect user data, but we cannot guarantee absolute security due to the nature of the internet.</p>
                    <h4>4. Third-Party Services</h4>
                    <p>We may use third-party services for data analytics. These services have their own privacy policies, and we are not responsible for their practices.</p>
                    <h4>5. Changes to the Policy</h4>
                    <p>We may update this privacy policy from time to time. Users will be notified of any significant changes.</p>
                    <p>Last Updated: October 22, 2024</p>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default PrivacyPolicy;
