import React, { createContext, useContext, useState } from "react";
import axios from "axios";

export const EarthModelContext = createContext();

export const fetchEarthModelData = async (wellId, wellboreId, wellDesignCaseId, token) => {
    const baseURL = process.env.REACT_APP_API_BASE_URL;

    try {
        const response = await axios({
            method: "GET",
            url: `${baseURL}/input/earth_model/${wellId}/${wellboreId}/${wellDesignCaseId}`,
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching earth model data:", error);
        throw error;
    }
};

export const EarthModelProvider = ({ children }) => {
    const [earthModelData, setEarthModelData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchAndSetEarthModelData = async (wellId, wellboreId, wellDesignCaseId, token) => {
        setLoading(true);
        setError(null);
        try {
            const data = await fetchEarthModelData(wellId, wellboreId, wellDesignCaseId, token);
            setEarthModelData(data);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <EarthModelContext.Provider value={{ earthModelData, fetchAndSetEarthModelData, loading, error }}>
            {children}
        </EarthModelContext.Provider>
    );
};

export const useEarthModel = () => useContext(EarthModelContext);
