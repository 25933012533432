import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isTwoFactorEnabled, setIsTwoFactorEnabled] = useState(false);
    const [isTwoFactorVerified, setIsTwoFactorVerified] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);  // New state for admin status

    return (
        <AuthContext.Provider value={{
            isAuthenticated,
            setIsAuthenticated,
            isTwoFactorEnabled,
            setIsTwoFactorEnabled,
            isTwoFactorVerified,
            setIsTwoFactorVerified,
            isAdmin,
            setIsAdmin, // Setter for admin status
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
