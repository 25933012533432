import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import useToken from './useToken';

function TwoFactorSettings() {
    const { token } = useToken();
    const [qrCodeUrl, setQrCodeUrl] = useState(null);
    const [is2faEnabled, setIs2faEnabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables

    useEffect(() => {
        // Fetch user's 2FA status when the component mounts
        const fetch2FAStatus = async () => {
            try {
                const response = await axios.get(`${baseURL}/get_2fa_status`, {
                    withCredentials: true
                });
                setIs2faEnabled(response.data.two_factor_enabled);
                console.log('2tfa enbled?', response.data.two_factor_enabled);
            } catch (error) {
                console.error('Error fetching 2FA status', error);
            }
        };

        fetch2FAStatus();
    }, [baseURL, token]);

    const handleEnable2FA = async () => {
        setLoading(true);
        setError('');
        try {
            const response = await axios.post(`${baseURL}/enable_2fa`, {}, { // Pass an empty object for data
                withCredentials: true
            });
            setQrCodeUrl(response.data.otp_uri);
            setIs2faEnabled(true);
        } catch (error) {
            setError('Failed to enable 2FA. Please try again.');
            console.error('Error enabling 2FA:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDisable2FA = async () => {
        setLoading(true);
        setError('');
        try {
            await axios.post(`${baseURL}/disable_2fa`, {}, {
                withCredentials: true
            });
            setIs2faEnabled(false);
            setQrCodeUrl(null);
        } catch (error) {
            setError('Failed to disable 2FA. Please try again.');
            console.error('Error disabling 2FA:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>

        <div className='mt-5 border-top p-2'>
            <h6 className='text-primary'>Two-Factor Authentication (2FA)</h6>
            <p>2FA can significantly reduce the risk of successful phishing attacks and automated login attempts using stolen credentials.
                It ensures that even if an attacker obtains a user’s email and password, they cannot access the account without the second factor.</p>
            <p>Please enable or disable 2FA here.</p>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {is2faEnabled ? (
                <Button onClick={handleDisable2FA} disabled={loading}>
                    {loading ? 'Disabling...' : 'Disable 2FA'}
                </Button>
            ) : (
                <Button className='primary' onClick={handleEnable2FA} disabled={loading}>
                    {loading ? 'Enabling...' : 'Enable 2FA'}
                </Button>
            )} {' '}
            {is2faEnabled ? (
                '2FA is enabled.'
            ) : (
                <span className="text-danger">2FA is disabled. Please consider enabling it.</span>
            )}

            {qrCodeUrl && (
                <div>
                    <p>Scan this QR code with your authenticator app:</p>
                    <img
                        src={`https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(
                            qrCodeUrl
                        )}`}
                        alt="2FA QR Code"
                    />
                    <p>
                        Use Google Authenticator or any TOTP-compatible app to scan this QR code.
                    </p>
                </div>
            )}
            </div>

        </>
    );
}

export default TwoFactorSettings;
