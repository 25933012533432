import React, { useState } from "react";
import axios from "axios";
import { Button, Form, ProgressBar, Alert } from "react-bootstrap";

const LogoUploader = ({ userId }) => {
    const [logoFile, setLogoFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            // Validate file type (allow only images) and size (max 2MB)
            if (!file.type.startsWith("image/")) {
                setError("Please upload an image file.");
                return;
            }
            if (file.size > 2 * 1024 * 1024) {
                setError("File size must be less than 2MB.");
                return;
            }
            setError("");
            setLogoFile(file);
            setPreviewUrl(URL.createObjectURL(file));
        }
    };

    const handleUpload = async () => {
        if (!logoFile) return;

        const formData = new FormData();
        formData.append("logo", logoFile);
        formData.append("userId", userId); // Send user ID to associate with the logo

        try {
            const response = await axios.post("/upload/logo", formData, {
                headers: { "Content-Type": "multipart/form-data" },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(percentCompleted);
                },
            });
            setSuccessMessage("Logo uploaded successfully!");
            console.log("Response:", response.data);
        } catch (error) {
            console.error("Error uploading logo:", error);
            setError("An error occurred during the upload. Please try again.");
        } finally {
            setUploadProgress(0); // Reset progress
        }
    };

    return (
        <div>
            <Form.Group>
                <Form.Label>Upload Company Logo</Form.Label>
                <Form.Control type="file" onChange={handleFileChange} accept="image/*" />
            </Form.Group>
            {error && <Alert variant="danger">{error}</Alert>}
            {successMessage && <Alert variant="success">{successMessage}</Alert>}
            {previewUrl && <img src={previewUrl} alt="Logo Preview" style={{ width: 100, height: 100 }} />}
            {uploadProgress > 0 && <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />}
            <Button onClick={handleUpload} disabled={!logoFile}>Upload Logo</Button>
        </div>
    );
};

export default LogoUploader;
