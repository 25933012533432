import { atan, cos, sin, tan, sqrt, acos, round as mathRound } from 'mathjs';

// Conversion constants
const DEG_TO_RAD = Math.PI / 180;
const RAD_TO_DEG = 180 / Math.PI;

// Example of declaring variables
const w1 = 0; // or some computed value
const w2 = 0;
const w3 = 0;

const u1 = 0;
const u2 = 0;
const u3 = 0;

const v1 = 0;
const v2 = 0;
const v3 = 0;

const x1 = 0;
const x2 = 0;
const x3 = 0;

export default function interpolateSurv(surveyData, ip) {
    // const length = surveyData.length;

    // Create a list of measured depths
    const mdList = surveyData.map((data) => data.md_survey);

    if (ip < 0 || ip > mdList[mdList.length - 1]) {
        throw new Error('Interpolation depth is out of range');
    }

    const res = mdList.findIndex((val) => val > ip);

    if (res === -1 || res === 0) {
        throw new Error('Interpolation point is out of range of survey data');
    }

    const md1 = surveyData[res - 1].md_survey;
    const tvd1 = surveyData[res - 1].tvd_survey;
    const inc1 = surveyData[res - 1].incl_survey * DEG_TO_RAD;
    const az1 = surveyData[res - 1].azim_survey * DEG_TO_RAD;

    const md2 = surveyData[res].md_survey;
    const tvd2 = surveyData[res].tvd_survey;
    const inc2 = surveyData[res].incl_survey * DEG_TO_RAD;
    const az2 = surveyData[res].azim_survey * DEG_TO_RAD;

    const md = md2 - md1;
    const dmd = ip - md1;

    let tvd, azimuth, inclination, dl, dls;

    if (ip === md1) {
        tvd = tvd1;
        azimuth = az1 * RAD_TO_DEG;
        inclination = inc1 * RAD_TO_DEG;
        dl = 0;
        dls = 0;
    } else if (inc1 === inc2 && inc2 === 0) {
        azimuth = az2 * RAD_TO_DEG;
        inclination = inc1 * RAD_TO_DEG;
        tvd = customRound(((tvd2 - tvd1) / md * dmd + tvd1), 2);
        dl = 0;
        dls = 0;
    } else if (inc1 === inc2 && az1 !== az2) {
        const dtvd = customRound(dmd * cos(inc1), 2);
        tvd = dtvd + tvd1;
        inclination = inc1 * RAD_TO_DEG;

        const dl2 = acos(cos(inc2 - inc1) - sin(inc2) * sin(inc1) * (1 - cos(az2 - az1)));
        dl = dl2 * dmd / md;
        dls = (dl * RAD_TO_DEG / dmd) * 100;

        // Determine azimuth at the interpolation point
        azimuth = calculateAzimuth(w1, w2, w3);
    } else if (az1 === az2 && inc1 !== inc2) {
        const dtvd = customRound((dmd * (sin(inc2) - sin(inc1)) / (inc2 - inc1)), 2);
        tvd = dtvd + tvd1;
        azimuth = az1 * RAD_TO_DEG;

        const dl2 = acos(cos(inc2 - inc1) - sin(inc2) * sin(inc1) * (1 - cos(az2 - az1)));
        dl = dl2 * dmd / md;
        dls = (dl * RAD_TO_DEG / dmd) * 100;

        inclination = calculateInclination(u1, u2, u3, v1, v2, v3, x1, x2, x3, dl);
    } else if (inc1 === inc2 && az1 === az2) {
        tvd = customRound(((tvd2 - tvd1) / (md2 - md1) * (ip - md1) + tvd1), 2);
        inclination = inc1; // this is in degrees
        // azimuth = az1 * 180 / Math.pi;
        azimuth = az1 * RAD_TO_DEG;
        dl = 0;
        dls = 0;
    } else {
        const dl2 = acos(cos(inc2 - inc1) - sin(inc2) * sin(inc1) * (1 - cos(az2 - az1)));
        dl = dl2 * dmd / md;

        dls = (dl * RAD_TO_DEG / dmd) * 100;
        tvd = calculateTVD(tvd1, inc1, inc2, md1, md2, ip, dl);
        inclination = calculateInclination(u1, u2, u3, v1, v2, v3, x1, x2, x3, dl);
        azimuth = calculateAzimuth(w1, w2, w3);
    }

    return { ip, inclination, azimuth, tvd, dl, dls };
}

function calculateAzimuth(w1, w2, w3) {
    let azimuth = atan(Math.abs(w2 / w1)) * RAD_TO_DEG;

    if (w1 < 0 && w2 < 0) azimuth = 180 + azimuth;
    if (w1 < 0 && w2 >= 0) azimuth = 180 - azimuth;
    if (w1 >= 0 && w2 < 0) azimuth = 360 - azimuth;

    return azimuth;
}

function calculateInclination(u1, u2, u3, v1, v2, v3, x1, x2, x3, dl) {
    const k = sqrt(x1 ** 2 + x2 ** 2 + x3 ** 2);
    const y1 = u2 * u3 - u3 * x2;
    const y2 = u3 * x1 - u1 * x3;
    const y3 = u1 * x2 - u2 * x1;

    const H2 = -sin(dl);
    const H3 = cos(dl);

    const w1 = H2 * (y1 / k) + H3 * u1;
    const w2 = H2 * (y2 / k) + H3 * u2;
    const w3 = H2 * (y3 / k) + H3 * u3;

    return atan(sqrt(w1 ** 2 + w2 ** 2) / Math.abs(w3)) * RAD_TO_DEG;
}

function calculateTVD(tvd1, inc1, inc2, md1, md2, ip, dl) {
    const dmd = ip - md1;
    const r = tan(dl) / dl;
    return customRound(tvd1 + (dmd / 2) * (cos(inc1) + cos(inc2)) * r, 2);
}

function customRound(value, decimals) {
    return Number(mathRound(value + 'e' + decimals) + 'e-' + decimals);
}
