import { Edit, SimpleForm, TextInput } from 'react-admin';

const WellDesignCaseEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="well_design_case_name" />
        </SimpleForm>
    </Edit>
);

export default WellDesignCaseEdit;