import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { isPasswordStrong } from "../components/admin/isPasswordStrong";
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export default function RegisterPage() {
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordStrengthMessage, setPasswordStrengthMessage] = useState('');
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const navigate = useNavigate();
    // const { executeRecaptcha } = useGoogleReCaptcha();
    const [recaptchaToken, setRecaptchaToken] = useState(null); // State to store reCAPTCHA token


    const handleRecaptcha = async () => {
        // Get the reCAPTCHA site key from the environment variable
        const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
        console.log('recaptchaSiteKey', recaptchaSiteKey);

        // Call reCAPTCHA to get the token
        const token = await window.grecaptcha.execute(recaptchaSiteKey, { action: 'register' });
        console.log('token', token);

        setRecaptchaToken(token); // Store the reCAPTCHA token
    };


    useEffect(() => {
        handleRecaptcha(); // Automatically call reCAPTCHA when the component mounts
    }, []);

    const registerUser = async () => {
        setEmailErrorMessage('');

        if (passwordStrengthMessage !== "Password is strong.") {
            setEmailErrorMessage("Please ensure your password is strong.");
            return;
        }

        // if (!recaptchaToken) {
        //     console.error("reCAPTCHA not yet initialized");
        //     return;
        // }

        try {
            // const token = await executeRecaptcha("register");

            const response = await axios.post(`${baseURL}/signup`, {
                email: email,
                password: password,
                recaptchaToken: recaptchaToken
            });

            console.log(response);
            navigate("/signup_feedback");
        } catch (error) {
            console.log(error, 'error');
            if (error.response) {
                if (error.response.status === 409) {
                    setEmailErrorMessage("Email already exists. Please try another one.");
                } else {
                    setEmailErrorMessage("An error occurred during registration. Please try again.");
                }
            } else {
                setEmailErrorMessage("An unexpected error occurred. Please try again.");
            }
        }
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);

        const strengthMessage = isPasswordStrong(newPassword);
        setPasswordStrengthMessage(strengthMessage);
    };

    return (
        <section className="bg-light py-3 py-md-5 vh-100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
                        <div className="card border border-light-subtle rounded-3 shadow-sm">
                            <div className="card-body p-3 p-md-4 p-xl-5">
                                {/* <div className="text-center mb-3">
                                    <a href="#!">
                                        <img src="./assets/img/bsb-logo.svg" alt="Logo" width="175" height="57" />
                                    </a>
                                </div> */}
                                <h2 className="fs-6 fw-normal text-center text-secondary mb-4">
                                    Enter your details to register
                                </h2>
                                <form onSubmit={(e) => { e.preventDefault(); registerUser(); }}>
                                    <div className="row gy-2">
                                        <div className="col-12">
                                            <div className="form-floating mb-3">
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    id="email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    placeholder="Email"
                                                    required
                                                />
                                                <label htmlFor="email">Email</label>
                                                {emailErrorMessage && (
                                                    <div className="mt-2 text-danger">
                                                        {emailErrorMessage}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating mb-3">
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    id="password"
                                                    value={password}
                                                    onChange={handlePasswordChange}
                                                    placeholder="Password"
                                                    required
                                                />
                                                <label htmlFor="password">Password</label>
                                                {passwordStrengthMessage && (
                                                    <div className={`mt-2 text-${passwordStrengthMessage === "Password is strong." ? 'success' : 'danger'}`}>
                                                        {passwordStrengthMessage}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="terms"
                                                    required
                                                />
                                                <label className="form-check-label text-secondary" htmlFor="terms">
                                                    I agree to the <a href="/terms-of-use" className="link-primary text-decoration-none">terms and conditions</a>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="d-grid my-3">
                                                <button className="btn btn-primary btn-lg" type="submit">
                                                    Sign up
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <p className="m-0 text-secondary text-center">
                                                Already have an account? <Link to="/login" className="link-primary text-decoration-none">Sign in</Link>
                                            </p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
