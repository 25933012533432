import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col, Tabs, Tab, Alert } from "react-bootstrap";
import Dropzone from 'react-dropzone';
import Papa from 'papaparse';
import axios from 'axios';
import HelpComponent from '../../help/helpComponent';

function AddNewDrillCollarForm({ show, handleClose, onNewDrillCollarAdded }) {
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables

    const [key, setKey] = useState('dc');
    const [showSuccess, setShowSuccess] = useState(false);
    const [setFileData] = useState(null);
    const [uploadMethod, setUploadMethod] = useState('manual');
    const [showHelp, setShowHelp] = useState(false);

    const [formData, setFormData] = useState({
        dc_number: '',
        dc_od: '',
        dc_id: '',
        dc_range: '',
        dc_wt: '',
        dc_sec_mod: '',
        dc_bsr: '',
        dc_tj_od: '',
        dc_tj_id: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: name === 'dc_number' ? value : parseFloat(value)
        });
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        parseFile(file);
    };

    const handleDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        parseFile(file);
    };

    const parseFile = (file) => {
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: (result) => {
                const data = result.data[0];
                const parsedData = {
                    dc_number: data.dc_number,
                    dc_od: parseFloat(data.dc_od),
                    dc_id: parseFloat(data.dc_id),
                    dc_range: parseFloat(data.dc_range),
                    dc_wt: parseFloat(data.dc_wt),
                    dc_sec_mod: parseFloat(data.dc_sec_mod),
                    dc_bsr: parseFloat(data.dc_bsr),
                    dc_tj_od: parseFloat(data.dc_tj_od),
                    dc_tj_id: parseFloat(data.dc_tj_id)
                };
                setFormData(parsedData);
                setUploadMethod('manual');
            },
            error: (error) => {
                console.error('Error parsing file:', error);
            }
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        axios({
            method: 'POST',
            url: `${baseURL}/input/drill_collar_library`,
            withCredentials: true,
            data: formData
        })
            .then(response => {
                if (response.status === 201) {
                    onNewDrillCollarAdded();
                    setShowSuccess(true);
                    setTimeout(() => {
                        setShowSuccess(false);
                        handleClose();
                    }, 3000); // Close modal after 3 seconds
                }
            })
            .catch(error => {
                console.error('Error adding new drill collar:', error);
            });
    };

    const handleUploadMethodChange = (method) => {
        setUploadMethod(method);
        setFileData(null);
        setFormData({
            dc_number: '',
            dc_od: '',
            dc_id: '',
            dc_range: '',
            dc_wt: '',
            dc_sec_mod: '',
            dc_bsr: '',
            dc_tj_od: '',
            dc_tj_id: ''
        });
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add a new drill collar to the database</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="float-end">
                    <Button variant="outline-secondary" onClick={() => setShowHelp(true)} style={{ marginLeft: '10px' }}>
                        Help
                    </Button>
                </div>
                {showSuccess && (
                    <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible>
                        You have successfully added a new drill collar record to the database.
                        It should now be available for selection in the Add BHA Item form.
                    </Alert>
                )}
                <Tabs
                    id="controlled-tab-header"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3 mt-5"
                >
                    <Tab eventKey="dc" title="Drill Collar">
                        <div className="upload-method-selection mb-3">
                            <Button variant="outline-primary" onClick={() => handleUploadMethodChange('manual')}>
                                Enter Data Manually
                            </Button>
                            <Button variant="outline-primary" onClick={() => handleUploadMethodChange('file')} style={{ marginLeft: '10px' }}>
                                Upload File
                            </Button>
                            <Button variant="outline-primary" onClick={() => handleUploadMethodChange('dragdrop')} style={{ marginLeft: '10px' }}>
                                Drag & Drop File
                            </Button>
                        </div>
                        {uploadMethod === 'manual' && (
                            <Form onSubmit={handleSubmit}>
                                <Form.Group as={Row} controlId="dc_number">
                                    <Form.Label column sm={4}>DC Number</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            type="text"
                                            name="dc_number"
                                            value={formData.dc_number}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="dc_od">
                                    <Form.Label column sm={4}>Outer Diameter</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            type="number"
                                            step="any"
                                            name="dc_od"
                                            value={formData.dc_od}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="dc_id">
                                    <Form.Label column sm={4}>Inner Diameter</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            type="number"
                                            step="any"
                                            name="dc_id"
                                            value={formData.dc_id}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="dc_range">
                                    <Form.Label column sm={4}>Range</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            type="number"
                                            step="any"
                                            name="dc_range"
                                            value={formData.dc_range}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="dc_wt">
                                    <Form.Label column sm={4}>Weight</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            type="number"
                                            step="any"
                                            name="dc_wt"
                                            value={formData.dc_wt}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="dc_sec_mod">
                                    <Form.Label column sm={4}>Section Modulus</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            type="number"
                                            step="any"
                                            name="dc_sec_mod"
                                            value={formData.dc_sec_mod}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="dc_bsr">
                                    <Form.Label column sm={4}>BSR</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            type="number"
                                            step="any"
                                            name="dc_bsr"
                                            value={formData.dc_bsr}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="dc_tj_od">
                                    <Form.Label column sm={4}>Tool Joint OD</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            type="number"
                                            step="any"
                                            name="dc_tj_od"
                                            value={formData.dc_tj_od}
                                            onChange={handleInputChange}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="dc_tj_id">
                                    <Form.Label column sm={4}>Tool Joint ID</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            type="number"
                                            step="any"
                                            name="dc_tj_id"
                                            value={formData.dc_tj_id}
                                            onChange={handleInputChange}
                                        />
                                    </Col>
                                </Form.Group>
                                <Button variant="primary" type="submit" onClick={() => setShowSuccess(true)}>
                                    Add to Database
                                </Button>
                            </Form>
                        )}
                        {uploadMethod === 'file' && (
                            <Form onSubmit={handleSubmit}>
                                <Form.Group>
                                    <Form.Label>Upload a data file</Form.Label>
                                    <Form.Control type="file" onChange={handleFileUpload} />
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                    Upload
                                </Button>
                            </Form>
                        )}
                        {uploadMethod === 'dragdrop' && (
                            <Dropzone onDrop={handleDrop}>
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps({ className: 'dropzone' })} style={{ border: '2px dashed #007bff', padding: '20px', textAlign: 'center' }}>
                                        <input {...getInputProps()} />
                                        <p>Drag & drop a file here, or click to select one</p>
                                    </div>
                                )}
                            </Dropzone>
                        )}
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
            <HelpComponent
                show={showHelp}
                onHide={() => setShowHelp(false)}
                title="Add Drill Collar Form Help"
                content={
                    <div>
                        <p>This form allows you to add new drill collar records to the database.</p>
                        <p>You can choose to enter the data manually, upload a file, or drag and drop a file.</p>
                        <p>For manual entry, fill in all the required fields and click 'Add to Database'.</p>
                        <p>For file upload, click 'Upload a data file', select your file, and click 'Upload'.</p>
                        <p>For drag and drop, drag a file into the designated area, and the data will be populated automatically.</p>
                    </div>
                }
            />
        </Modal>
    );
}

export default AddNewDrillCollarForm;
