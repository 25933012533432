import { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode'; // Use named import
import { useNavigate } from 'react-router-dom';

const SessionManager = ({ token }) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            const decoded = jwtDecode(token); // Use jwtDecode here
            const expirationTime = decoded.exp * 1000; // convert to milliseconds
            const currentTime = Date.now();

            const timeUntilExpiration = expirationTime - currentTime;
            const timeUntilWarning = timeUntilExpiration - 5 * 60 * 1000; // 5 minutes before expiration

            if (timeUntilWarning > 0) {
                // Set timeout for warning
                const warningTimer = setTimeout(() => {
                    alert('Your session is about to expire in 5 minutes.');
                }, timeUntilWarning);

                // Set timeout for redirecting to home
                const logoutTimer = setTimeout(() => {
                    alert('Session expired. Redirecting to home.');
                    // Clear the token and any user data
                    localStorage.removeItem('token'); // or however you store the token
                    navigate('/');
                }, timeUntilExpiration);

                // Cleanup timers when the component is unmounted or token changes
                return () => {
                    clearTimeout(warningTimer);
                    clearTimeout(logoutTimer);
                };
            }
        }
    }, [token, navigate]);

    return null; // This component doesn't render any UI
};

export default SessionManager;
