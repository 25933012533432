import React from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

const EditEarthModelModal = ({
    show,
    handleClose,
    formData,
    handleInputChange,
    handleEditSubmit,
    formationNames,
    formationTypes
}) => {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Earth Model Data</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group as={Row} controlId="formTVD">
                        <Form.Label column sm={4}>TVD</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                name="tvd"
                                value={formData.tvd}
                                onChange={handleInputChange}
                                step="0.01"
                                required
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formName">
                        <Form.Label column sm={4}>Formation Name</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                as="select"
                                name="formation_name"
                                value={formData.formation_name}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Select Formation Name</option>
                                {formationNames.map((name, index) => (
                                    <option key={index} value={name.formation_name}>{name.formation_name}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formType">
                        <Form.Label column sm={4}>Formation Type</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                as="select"
                                name="formation_type"
                                value={formData.formation_type}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Select Formation Type</option>
                                {formationTypes.map((type, index) => (
                                    <option key={index} value={type.formation_type}>{type.formation_type}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formPorePress">
                        <Form.Label column sm={4}>Pore Press Gradient</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                name="pore_press"
                                value={formData.pore_press}
                                onChange={handleInputChange}
                                step="0.01"
                                required
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formFracGrad">
                        <Form.Label column sm={4}>Frac Gradient</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                name="frac_grad"
                                value={formData.frac_grad}
                                onChange={handleInputChange}
                                step="0.01"
                                required
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formTemp">
                        <Form.Label column sm={4}>Temperature</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                name="temp"
                                value={formData.temp}
                                onChange={handleInputChange}
                                step="0.01"
                                required
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formGasGrad">
                        <Form.Label column sm={4}>Gas Gradient</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                name="gas_grad"
                                value={formData.gas_grad}
                                onChange={handleInputChange}
                                step="0.01"
                                required
                            />
                        </Col>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleEditSubmit}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditEarthModelModal;
