import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import SignupConfirmation from './SignupConfirmation';
// import MainLayout from '../MainLayout';

export default function ConfirmEmail() {
    const { token } = useParams(); // Get the token from the URL
    const [status, setStatus] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        console.log('ConfirmEmail component mounted');
        console.log('Token:', token);
        
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/confirm/${token}`)
            .then(response => {
                setStatus(response.data.status);
                setMessage(response.data.message);

                // Redirect to login after 5 seconds if successful
                console.log('is this working???????');
                if (response.data.status === 'success') {
                    setTimeout(() => {
                        navigate('/login');
                    }, 2000);
                }
            })
            .catch(error => {
                if (error.response) {
                    setStatus('error');
                    setMessage(error.response.data.message);
                } else {
                    setStatus('error');
                    setMessage('An unexpected error occurred.');
                }
            });
    }, [token, navigate]);

    return (
        
        <div className="confirmation-container">
            <SignupConfirmation message={message} status={status} />
        </div>
        
    );
}
