import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Modal, Button, Form, Row, Col, Container, Alert } from "react-bootstrap";

export default function AddDrillBitForm({ show, handleClose, onNewBitAdded }) {
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables

    const [bitManufacturers, setBitManufacturers] = useState([]);
    const [newManufacturer, setNewManufacturer] = useState('');
    const [bitData, setBitData] = useState({
        bit_type: '',
        bit_size: '',
        iadc_code: '',
        bit_identification: '',
        bit_manufacturer_id: ''
    });

    const bitTypes = ["Milled Tooth Bit", "Insert Bit", "PDC", "Diamond Bit", "Natural Diamond Bit"];
    const [setShowHelp] = useState(false); // State for showing help modal
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false); // State for showing error alert

    const fetchBitManufacturers = useCallback(() => {
        axios.get(`${baseURL}/input/bit_manufacturers`, {
            withCredentials: true
        })
            .then(response => {
                setBitManufacturers(response.data);
            })
            .catch(error => {
                console.error("Error fetching bit manufacturers:", error);
            });
    },[baseURL]);

    useEffect(() => {
        fetchBitManufacturers();
    }, [fetchBitManufacturers]);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setBitData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleNewManufacturerChange = (e) => {
        setNewManufacturer(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowError(false); // Reset error state

        // If a new manufacturer is being added
        if (newManufacturer) {
            axios.post(`${baseURL}/input/bit_manufacturers`, { bit_manufacturer: newManufacturer }, {
                withCredentials: true
            })
                .then(response => {
                    const newManufacturerId = response.data.bit_manufacturer_id;
                    submitBitData(newManufacturerId);
                })
                .catch(error => {
                    console.error("Error adding new bit manufacturer:", error);
                    setShowError(true);
                });
        } else {
            submitBitData(bitData.bit_manufacturer_id);
        }
    };

    const submitBitData = (bitManufacturerId) => {
        const newBitData = { ...bitData, bit_manufacturer_id: bitManufacturerId };

        axios.post(`${baseURL}/input/bit_catalogue`, newBitData, {
            withCredentials: true
        })
            .then(response => {
                onNewBitAdded();
                setShowSuccess(true);
                handleClose();
            })
            .catch(error => {
                console.error("Error adding new drill bit:", error);
                setShowError(true);
            });
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add New Drill Bit</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-3">
                            <Button variant="outline-secondary" onClick={() => setShowHelp(true)} style={{ marginLeft: '10px' }}>
                                Help
                            </Button>
                        </div>
                    </Row>
                    <Row>
                        {showSuccess && (
                            <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible>
                                You have successfully added a new drill bit to the database.
                                It should now be available for selection in the Add Bit form.
                                Please close this dialog box to return to the Add Bit form.
                            </Alert>
                        )}
                        {showError && (
                            <Alert variant="danger" onClose={() => setShowError(false)} dismissible>
                                An error occurred while adding the new drill bit. Please try again.
                            </Alert>
                        )}
                    </Row>
                    <Row>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group as={Row} controlId="bitType">
                                <Form.Label column sm={4}>Bit Type</Form.Label>
                                <Col sm={8}>
                                    <Form.Control
                                        as="select"
                                        name="bit_type"
                                        value={bitData.bit_type}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value="">Select Bit Type</option>
                                        {bitTypes.map((type, index) => (
                                            <option key={index} value={type}>{type}</option>
                                        ))}
                                    </Form.Control>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="bitSize">
                                <Form.Label column sm={4}>Bit Size</Form.Label>
                                <Col sm={8}>
                                    <Form.Control
                                        type="number"
                                        name="bit_size"
                                        value={bitData.bit_size}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="iadcCode">
                                <Form.Label column sm={4}>IADC Code</Form.Label>
                                <Col sm={8}>
                                    <Form.Control
                                        type="text"
                                        name="iadc_code"
                                        value={bitData.iadc_code}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="bitIdentification">
                                <Form.Label column sm={4}>Bit Identification</Form.Label>
                                <Col sm={8}>
                                    <Form.Control
                                        type="text"
                                        name="bit_identification"
                                        value={bitData.bit_identification}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="bitManufacturer">
                                <Form.Label column sm={4}>Bit Manufacturer</Form.Label>
                                <Col sm={8}>
                                    <Form.Control
                                        as="select"
                                        name="bit_manufacturer_id"
                                        value={bitData.bit_manufacturer_id}
                                        onChange={handleInputChange}
                                        required
                                        disabled={newManufacturer !== ''}
                                    >
                                        <option value="">Select Manufacturer</option>
                                        {bitManufacturers.map((manufacturer, index) => (
                                            <option key={index} value={manufacturer.bit_manufacturer_id}>
                                                {manufacturer.bit_manufacturer}
                                            </option>
                                        ))}
                                    </Form.Control>
                                    <Form.Text className="text-muted">
                                        If the manufacturer is not listed, add a new one below.
                                    </Form.Text>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="newManufacturer">
                                <Form.Label column sm={4}>New Manufacturer</Form.Label>
                                <Col sm={8}>
                                    <Form.Control
                                        type="text"
                                        value={newManufacturer}
                                        onChange={handleNewManufacturerChange}
                                        placeholder="Enter new manufacturer"
                                    />
                                </Col>
                            </Form.Group>

                            <Button variant="primary" type="submit">
                                Add Drill Bit
                            </Button>
                        </Form>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
}
