import React, { useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import axios from "axios";

const AddRigEquipmentForm = ({ show, handleClose, wellId, wellboreId, wellDesignCaseId, onEquipmentAdded }) => {
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables

    const [surfEquipClass, setSurfEquipClass] = useState("");
    const [pumpNum, setPumpNum] = useState("");
    const [linerSize, setLinerSize] = useState("");
    const [strokeLen, setStrokeLen] = useState("");
    const [pumpSpm, setPumpSpm] = useState("");
    const [pumpEff, setPumpEff] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        const newRigEquipment = {
            well_id: parseFloat(wellId),
            wellbore_id: parseFloat(wellboreId),
            well_design_case_id: parseFloat(wellDesignCaseId),
            surf_equip_class: surfEquipClass,
            pump_num: pumpNum,
            liner_size: parseFloat(linerSize),
            stroke_len: parseFloat(strokeLen),
            pump_spm: parseFloat(pumpSpm),
            pump_eff: parseFloat(pumpEff),
        };

        axios.post(`${baseURL}/input/rig_equipment`, newRigEquipment, {
            withCredentials: true
        })
            .then(response => {
                onEquipmentAdded();
                handleClose();
            })
            .catch(error => {
                console.error("Error adding rig equipment:", error);
            });
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add Rig Equipment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group as={Row} controlId="formSurfEquipClass">
                        <Form.Label column sm={4}>Class</Form.Label>
                        <Col sm={8}>
                        <Form.Control as="select" value={surfEquipClass} onChange={(e) => setSurfEquipClass(e.target.value)}>
                            <option value="">Select Case</option>
                                <option value="Class 1">Case 1</option>
                                <option value="Class 2">Case 2</option>
                                <option value="Class 3">Case 3</option>
                                <option value="Class 4">Case 4</option>
                                <option value="Class 4">Case 5</option>
                        </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formPumpNum">
                        <Form.Label column sm={4}>Pump</Form.Label>
                        <Col sm={8}>
                        <Form.Control as="select" value={pumpNum} onChange={(e) => setPumpNum(e.target.value)}>
                            <option value="">Select Pump</option>
                            <option value="Pump 1">Pump 1</option>
                            <option value="Pump 2">Pump 2</option>
                            <option value="Pump 3">Pump 3</option>
                            <option value="Pump 4">Pump 4</option>
                        </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formLinerSize">
                        <Form.Label column sm={4}>Liner Size</Form.Label>
                        <Col sm={8}>
                            <Form.Control type="number" step="0.01" value={linerSize} onChange={(e) => setLinerSize(e.target.value)} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formStrokeLen">
                        <Form.Label column sm={4}>Stroke Size</Form.Label>
                        <Col sm={8}>
                            <Form.Control type="number" step="0.01" value={strokeLen} onChange={(e) => setStrokeLen(e.target.value)} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formPumpSpm">
                        <Form.Label column sm={4}>Pump SPM</Form.Label>
                        <Col sm={8}>
                            <Form.Control type="number" step="0.01" value={pumpSpm} onChange={(e) => setPumpSpm(e.target.value)} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formPumpEff">
                        <Form.Label column sm={4}>Pump Efficiency</Form.Label>
                        <Col sm={8}>
                            <Form.Control type="number" step="0.01" value={pumpEff} onChange={(e) => setPumpEff(e.target.value)} />
                        </Col>
                    </Form.Group>

                    <Button variant="primary" type="submit">
                        Add Equipment
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default AddRigEquipmentForm;
