import React, { useContext } from 'react';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import RheologyHeader from "../../components/well_information/rheology/rheology";
import RigEquipmentHeader from "../../components/well_information/rig_equipment/rigEquipment";
import BitHeader from "../../components/well_information/bits/bit";
// import BhaHeader from '../../components/well_information/BhaHeader';
import BhaHeader from '../../components/well_information/bha_items/BhaHeader';
import EarthModelTable from '../../components/well_information/earth_model/EarthModelTable';
import { GlobalStateContext } from "../../components/GlobalStateContext";
import DrillingParametersTable from '../../components/well_information/drilling_parameters/DrillingParametersTable';
// import { useHoleSection } from "./HoleSectionProvider";

function WellInformationTabs() {
    const { selectedItems } = useContext(GlobalStateContext);
    // const { holeSection } = useHoleSection();

    return (
        <Tabs
            id="controlled-tab-well_information"
            defaultActiveKey="rheology"
            className="mb-3 mt-5"
        >
            <Tab eventKey="tips_well_info" title="Tips">
                <div className="home container">
                    <div className="">
                        <h5>Well Information</h5>
                        {/* <p>Selected company: {selectedItems.company_name}: {selectedItems.block_name}: {selectedItems.field_name}</p> */}
                        <p>Create, read, update and delete well information for {selectedItems.wellbore_name} {selectedItems.well_design_case_name}:</p>
                        <ul>
                            <li>Rheology</li>
                            <li>Drill bits</li>
                            <li>String/BHA</li>
                            <li>Rig equipment</li>
                            <li>Earth model</li>
                            <li>Drilling parameters</li>
                        </ul>
                    </div>
                </div>
            </Tab>
            <Tab eventKey="rheology" title="Rheology">
                <div className="mx-4">
                    <p>
                {selectedItems.wellbore_name}: Rheology information for {selectedItems.well_design_case_name}
                    </p>
                </div>
                <RheologyHeader wellboreId={selectedItems.wellbore_id} wellId={selectedItems.well_id} wellDesignCaseId={selectedItems.well_design_case_id} />

            </Tab>
            <Tab eventKey="drill_bits" title="Drill Bits" >
                <div className="mx-4">
                    <p>
                        {selectedItems.wellbore_name}: Drill bit information for {selectedItems.well_design_case_name}

                    </p>
                </div>
                <BitHeader wellboreId={selectedItems.wellbore_id} wellId={selectedItems.well_id} wellDesignCaseId={selectedItems.well_design_case_id} />
            </Tab>
            <Tab eventKey="string" title="String/BHA">
                <div className="mx-4">
                    <p>
                {selectedItems.wellbore_name}: BHA information for {selectedItems.well_design_case_name}
                    </p>
                </div>
                
                <BhaHeader wellboreId={selectedItems.wellbore_id} wellId={selectedItems.well_id} wellDesignCaseId={selectedItems.well_design_case_id} />

            </Tab>
            <Tab eventKey="rig_equipment" title="Rig equipment" >
                <div className="mx-4">
                    <p>
                        {selectedItems.wellbore_name}: Rig equipment information for {selectedItems.well_design_case_name}
                    </p>
                </div>
                <RigEquipmentHeader wellboreId={selectedItems.wellbore_id} wellId={selectedItems.well_id} wellDesignCaseId={selectedItems.well_design_case_id} />
            </Tab>
            <Tab eventKey="earth_model" title="Earth model" >
                <div className="mx-4">
                    <p>
                        {selectedItems.wellbore_name}: Earth Model data for {selectedItems.well_design_case_name}
                    </p>
                </div>
                <EarthModelTable wellboreId={selectedItems.wellbore_id} wellId={selectedItems.well_id} wellDesignCaseId={selectedItems.well_design_case_id} />
            </Tab>
            <Tab eventKey="drilling_parameters" title="Drilling parameters" >
                <div className="mx-4">
                    <p>
                        {selectedItems.wellbore_name}: Drilling Parameters for {selectedItems.well_design_case_name}
                    </p>
                </div>
                <DrillingParametersTable wellboreId={selectedItems.wellbore_id} wellId={selectedItems.well_id} wellDesignCaseId={selectedItems.well_design_case_id} />

            </Tab>
            
        </Tabs>
    );
}

export default WellInformationTabs;
