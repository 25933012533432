import React, { } from "react";
import CasingDimensionsLookup from "../components/engineering_tools/CasingDimensionsLookup";

export default function CasingDimensionsPage() {


    return (
        <div>
            <div className="container vh-100">
                <div className="row vh-100">
                    <div className="col-12">

                        <CasingDimensionsLookup />
                    </div>
                </div>
            </div>
        </div>
    );
}