import React from "react";
// import { Link } from "react-router-dom";
// import HoleSections from "../HoleSectionTable";
// import RheologyTable from "./rheologyTable";
// import RigEquipmentTable from "./rigEquipmentTable";
import BitTable from "./bitTable";
// import SidebarRight from "../SidebarRight";
import useToken from '../../admin/useToken'; // Import the custom hook


const BitHeader = ({ selectedCompany, selectedBlock, selectedField, selectedWell, selectedWellbore, selectedWellDesignCase, wellboreId, wellId, wellDesignCaseId, unique_drill_bit_id }) => {
    const { token, setToken } = useToken(); // Use the custom hook to manage token state

    return (
        <div className="container">


            <div className="row">
                <div className="col">
                    <BitTable wellId={wellId} wellboreId={wellboreId} wellDesignCaseId={wellDesignCaseId} unique_drill_bit_id={unique_drill_bit_id} token={token} setToken={setToken} />
                    {/* <RheologyTable wellId={1} wellboreId={2} wellDesignCaseId={1} token={token} setToken={setToken} /> */}

                    {/* <button type="submit" className="btn btn-primary">
                        Add
                    </button> */}
                </div>
                <div className="col col-lg-2">

                    {/* <SidebarRight /> */}
                </div>
            </div>
        </div>
    );
};

export default BitHeader;
