import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';

function AddBlockModal({ companyId, companyName, onSubmit, onClose }) {
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables

    const [blockName, setBlockName] = useState("");
    const [saving, setSaving] = useState(false); // State to track saving process

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSaving(true); // Set saving state to true while request is in progress
        try {

            // Make HTTP POST request to save the new block name with authorization headers
            await axios.post(
                `${baseURL}/input/blocks`,
                {
                    company_id: companyId, // Use the companyId prop passed from parent component
                    block_name: blockName
                },
                {
                    withCredentials: true
                }
            );

            // Call onSubmit prop with the saved block name
            console.log("Before resetting block name:", blockName);
            onSubmit(blockName);
            setBlockName(""); // Reset the block name after submission
            console.log("After resetting block name:", blockName);

            alert(`${blockName} successfully added to ${companyName}`);
            onClose(); // Close the modal only after successful submission
        } catch (error) {
            console.error('Error saving block name:', error);
            alert(`${blockName} was not added!`);
        } finally {
            setSaving(false); // Reset saving state after request is completed
        }
    };

    return (
        <Modal show={true} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add a new block for {companyName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Label>Block Name</Form.Label>
                    <Form.Control size="lg"
                        type="text"
                        placeholder="Enter the name of the new block"
                        value={blockName}
                        onChange={(e) => setBlockName(e.target.value)}
                    />
                    {/* <Form.Label>
                        Block Name:
                        <input
                            id="block"
                            type="text"
                            placeholder="Enter the block name"
                            value={blockName}
                            onChange={(e) => setBlockName(e.target.value)}
                        />
                    </Form.Label> */}

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose} disabled={saving}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit} disabled={saving}>
                    {saving ? 'Saving...' : 'Save'} {/* Show different text based on saving state */}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddBlockModal;
