import React, { useState, useEffect, useContext } from 'react';
import { GlobalStateContext } from '../../GlobalStateContext';
import { Form, Button, Row, Col } from 'react-bootstrap';

const DrillingParameterForm = ({ parameter, onSubmit }) => {
    const { selectedItems } = useContext(GlobalStateContext);
    const [formValues, setFormValues] = useState({
        well_id: selectedItems.well_id,
        wellbore_id: selectedItems.wellbore_id,
        well_design_case_id: selectedItems.well_design_case_id,
        rpm: '',
        rop: '',
        pipe_eccentricity: '',
        motor_pressure_drop: '',
        ecd_limit: '',
        wob: '',
        torque_at_bit: '',
        string_depth: ''
    });

    const openHoleParameters = selectedItems.openHoleParameters;


    useEffect(() => {
        if (parameter) {
            setFormValues({
                rpm: parameter.rpm || '',
                rop: parameter.rop || '',
                pipe_eccentricity: parameter.pipe_eccentricity || '',
                motor_pressure_drop: parameter.motor_pressure_drop || '',
                ecd_limit: parameter.ecd_limit || '',
                wob: parameter.wob || '',
                torque_at_bit: parameter.torque_at_bit || '',
                string_depth: parameter.string_depth || ''
            });
        }
    }, [parameter]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const parsedValues = {
            ...formValues,
            rpm: formValues.rpm ? parseFloat(formValues.rpm) : null,
            rop: formValues.rop ? parseFloat(formValues.rop) : null,
            pipe_eccentricity: formValues.pipe_eccentricity ? parseFloat(formValues.pipe_eccentricity) : null,
            motor_pressure_drop: formValues.motor_pressure_drop ? parseFloat(formValues.motor_pressure_drop) : null,
            ecd_limit: formValues.ecd_limit ? parseFloat(formValues.ecd_limit) : null,
            wob: formValues.wob ? parseFloat(formValues.wob) : null,
            torque_at_bit: formValues.torque_at_bit ? parseFloat(formValues.torque_at_bit) : null,
            string_depth: formValues.string_depth ? parseFloat(formValues.string_depth) : null,
        };
        onSubmit(parsedValues);
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Col>
                    <Form.Group controlId="rpm">
                        <Form.Label>RPM</Form.Label>
                        <Form.Control
                            type="text"
                            name="rpm"
                            value={formValues.rpm}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="rop">
                        <Form.Label>ROP</Form.Label>
                        <Form.Control
                            type="text"
                            name="rop"
                            value={formValues.rop}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId="pipe_eccentricity">
                        <Form.Label>Pipe Eccentricity</Form.Label>
                        <Form.Control
                            type="text"
                            name="pipe_eccentricity"
                            value={formValues.pipe_eccentricity}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="motor_pressure_drop">
                        <Form.Label>Motor Pressure Drop</Form.Label>
                        <Form.Control
                            type="text"
                            name="motor_pressure_drop"
                            value={formValues.motor_pressure_drop}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId="ecd_limit">
                        <Form.Label>ECD Limit</Form.Label>
                        <Form.Control
                            type="text"
                            name="ecd_limit"
                            value={formValues.ecd_limit}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="wob">
                        <Form.Label>WOB</Form.Label>
                        <Form.Control
                            type="text"
                            name="wob"
                            value={formValues.wob}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId="torque_at_bit">
                        <Form.Label>Torque at Bit</Form.Label>
                        <Form.Control
                            type="text"
                            name="torque_at_bit"
                            value={formValues.torque_at_bit}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="string_depth">
                        <Form.Label>Bit Depth</Form.Label>
                        <Form.Control
                            type="number"
                            name="string_depth"
                            value={formValues.string_depth || openHoleParameters.depth_md}
                            onChange={handleChange}
                            max={openHoleParameters.depth_md} // Set maximum value
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Button variant="primary" type="submit">
                Submit
            </Button>
        </Form>
    );
};

export default DrillingParameterForm;
