import React, { useState, useEffect } from "react";
import axios from "axios";
import TwoFactorSettings from "./admin/TwoFactorSettings";
import PasswordChange from "./admin/PasswordChange";
import LogoUploader from "./admin/LogoUploader";
import { Button, Col, Row } from "react-bootstrap";

function Profile() {
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Base URL from environment variables
    const [profileData, setProfileData] = useState({
        profileId: "",
        profile_email: "",
        profileName: "",
        profileJobTitle: "",
        profileCompany: "",
        profilePhoneNumber: "",
        profileLogoPath: "", // Store logo path
        profilLogoExists: false // Track if a logo exists
    });
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        // Fetch profile data on mount
        const getUserProfile = async () => {
            try {
                const response = await axios.get(`${baseURL}/profile`, {
                    withCredentials: true, // Sends cookies with request
                });
                const { user_id, email, full_name, job_title, company_name, phone_number, logo_path } = response.data;

                setProfileData({
                    profileId: user_id,
                    profile_email: email,
                    profileName: full_name,
                    profileJobTitle: job_title,
                    profileCompany: company_name,
                    profilePhoneNumber: phone_number,
                    profilLogoExists: !!logo_path,
                    profileLogoPath: logo_path ? `${baseURL}/${logo_path}` : "" // Prefix if logo path exists
                });
            } catch (error) {
                console.error("Error fetching user profile:", error.response.data);
            }
        };
        getUserProfile();
    }, [baseURL]);

    // Handle form field changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProfileData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Toggle editing mode
    const handleEditToggle = () => {
        setIsEditing(!isEditing);
    };

    // Save profile updates
    const handleSaveProfile = async () => {
        try {
            const response = await axios.put(`${baseURL}/profile`, {
                full_name: profileData.profileName,
                job_title: profileData.profileJobTitle,
                company_name: profileData.profileCompany,
                phone_number: profileData.profilePhoneNumber,
            }, { withCredentials: true });

            if (response.status === 200) {
                console.log("Profile updated successfully!");
                setIsEditing(false); // Exit edit mode on success
            }
        } catch (error) {
            console.error("Error updating profile:", error.response.data);
        }
    };

    return (
        <div className="container">
            <div className="row d-flex justify-content-center align-items-center h-50">
                <div className="col col-lg-12">
                    <div className="mb-3">
                        {profileData && (
                            <div className="row g-0">
                                <div className="col-md-3 bg-c-white text-center text-white">
                                    <img
                                        src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"
                                        alt="Profile Avatar"
                                        className="img-fluid my-5"
                                        width="150"
                                    />
                                    <h5>{profileData.profile_email}</h5>
                                </div>

                                <div className="col-md-9">
                                    <div className="p-4">
                                        <h3>Profile</h3>
                                        <div className="row pt-1">
                                            {/* Logo Uploader and Existing Logo Display */}
                                            <div className="col mb-3">
                                                <LogoUploader userId={profileData.profileId} onUploadSuccess={setProfileData} />
                                            </div>
                                            <div className="col-6 mb-3">
                                                {/* Show existing logo if it exists */}
                                                {profileData.profilLogoExists ? (
                                                    <img
                                                        src={profileData.profileLogoPath}
                                                        alt="Company Logo"
                                                        style={{ maxHeight: "150px", objectFit: "contain" }}
                                                    />
                                                ) : (
                                                    <p>No logo available</p>
                                                )}
                                            </div>

                                        </div>
                                        <h6>Company</h6>
                                        <div className="d-flex justify-content-start">
                                            {isEditing ? (
                                                <input
                                                    type="text"
                                                    name="profileCompany"
                                                    value={profileData.profileCompany}
                                                    onChange={handleInputChange}
                                                    className="form-control"
                                                />
                                            ) : (
                                                <p className="text-muted">
                                                    {profileData.profileCompany}
                                                </p>
                                            )}
                                        </div>

                                        {/* Other Profile Fields */}
                                        <div className="row pt-1">
                                            <div className="col-6 mb-3">
                                                <h6>Email</h6>
                                                <p className="text-muted">
                                                    {profileData.profile_email}
                                                </p>
                                            </div>
                                            <div className="col-6 mb-3">
                                                <h6>Job Title</h6>
                                                {isEditing ? (
                                                    <input
                                                        type="text"
                                                        name="profileJobTitle"
                                                        value={profileData.profileJobTitle}
                                                        onChange={handleInputChange}
                                                        className="form-control"
                                                    />
                                                ) : (
                                                    <p className="text-muted">
                                                        {profileData.profileJobTitle}
                                                    </p>
                                                )}
                                            </div>
                                            <div className="col-6 mb-3">
                                                <h6>Full Name</h6>
                                                {isEditing ? (
                                                    <input
                                                        type="text"
                                                        name="profileName"
                                                        value={profileData.profileName}
                                                        onChange={handleInputChange}
                                                        className="form-control"
                                                    />
                                                ) : (
                                                    <p className="text-muted">
                                                        {profileData.profileName}
                                                    </p>
                                                )}
                                            </div>
                                            {/* Other Fields like Job Title, Phone, etc */}

                                            <div className="col-6 mb-3">
                                                <h6>Phone Number</h6>
                                                {isEditing ? (
                                                    <input
                                                        type="text"
                                                        name="profilePhoneNumber"
                                                        value={profileData.profilePhoneNumber}
                                                        onChange={handleInputChange}
                                                        className="form-control"
                                                    />
                                                ) : (
                                                    <p className="text-muted">
                                                        {profileData.profilePhoneNumber}
                                                    </p>
                                                )}
                                            </div>
                                            
                                            <Row className="mb-3">
                                                <Col>
                                                    <div className="d-flex mt-3">
                                                        {isEditing ? (
                                                            <>
                                                                <Button variant="primary" onClick={handleSaveProfile}>
                                                                    Save
                                                                </Button>
                                                                <Button variant="secondary" className="ms-2" onClick={handleEditToggle}>
                                                                    Cancel
                                                                </Button>
                                                            </>
                                                        ) : (
                                                            <Button variant="primary" onClick={handleEditToggle}>
                                                                Edit profile
                                                            </Button>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>

                                            <div className="mt-3">
                                                <TwoFactorSettings />
                                            </div>
                                            <div className="mt-5 border-top">
                                                <PasswordChange />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;
