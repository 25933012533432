import React, { } from "react";
import About from "../components/About";

export default function AboutPage() {

    return (
        <div>
            <div className="container h-10">
                <div className="row h-10">
                    <div className="col-12">
                        <About />
                    </div>
                </div>
            </div>
        </div>
    );
}