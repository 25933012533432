import React from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";

export default function Footer() {
    return (
        <footer className="footer py-2 mt-auto border-top bg-light">
            <Container>
                <div className="d-flex flex-column flex-md-row justify-content-between text-primary text-center text-md-start">
                    <ul className="navbar-nav flex-column flex-md-row mb-2 mb-md-0">
                        <li className="nav-item mx-3">
                            <a className="nav-link" href="https://www.welldesigner.io">www.welldesigner.io</a>
                        </li>
                    </ul>
                    <ul className="navbar-nav flex-column flex-md-row">
                        <li className="nav-item mx-3">
                            <Link className="nav-link" to="/terms-of-use">Terms of Service</Link>
                        </li>
                        <li className="nav-item mx-3">
                            <Link className="nav-link" to="/privacy-policy">Privacy</Link>
                        </li>
                        <li className="nav-item mx-3">
                            <Link className="nav-link" to="/cookies">Cookies</Link>
                        </li>
                        {/* <li className="nav-item mx-3">
                            <a className="nav-link" href="#nds">© 2024 nds-labs.com</a>
                        </li> */}
                    </ul>
                </div>
            </Container>
        </footer>
    );
}
