import { Edit, SimpleForm, TextInput, BooleanInput } from 'react-admin';

const UserEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="full_name" />
            <TextInput source="email" />
            <TextInput source="job_title" />
            <TextInput source="company_name" />
            <BooleanInput source="two_factor_enabled" />
            <BooleanInput source="is_admin" />
        </SimpleForm>
    </Edit>
);

export default UserEdit;
