import React, { } from "react";
import SurveyInterpolation from "../components/engineering_tools/SurveyInterpolation";

export default function SurveyInterpolationPage() {


    return (
        <div>
            <div className="container h-50">
                <div className="row h-50">
                    <div className="col-12">

                        <SurveyInterpolation />
                    </div>
                </div>
            </div>
        </div>
    );
}