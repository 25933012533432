import React, { useState } from 'react';
import { Form, Button, Table, Container, Row, Col } from 'react-bootstrap';

function CapacityCalculator() {
    const [holeDiameter, setHoleDiameter] = useState('');
    const [length, setLength] = useState('');
    const [casingOD, setCasingOD] = useState('');

    const [casingID, setCasingID] = useState('');
    const [numStrings, setNumStrings] = useState(0);
    const [results, setResults] = useState(null);
    const [unit, setUnit] = useState('in');
    const [lengthUnit, setLengthUnit] = useState('ft');

    const calculateCapacities = () => {
        if (!holeDiameter || !length || !casingID) {
            alert('All fields must be filled!');
            return;
        }

        let pipeDia = parseFloat(casingOD);
        let casingDia = parseFloat(casingID);
        let totalLength = parseFloat(length);

        // Convert units if necessary
        if (unit === 'cm') {
            pipeDia *= 0.393701;
            casingDia *= 0.393701;
        }

        if (lengthUnit === 'm') {
            totalLength *= 3.281;
        }
        console.log('numStrings', numStrings);
        console.log('holeDiameter', holeDiameter);
        console.log('casingOD', casingOD);

        let annCap = 0;

        // Calculate annular capacity based on the number of concentric strings
        if (numStrings === 0) {
            annCap = (holeDiameter ** 2) / 1029.46; // bbl/ft
        } else if (numStrings === 1) {
            annCap = (holeDiameter ** 2 - casingOD ** 2) / 1029.46; // bbl/ft
        } else {
            let pipeVol = 0;
            for (let i = 0; i < numStrings; i++) {
                pipeVol += pipeDia ** 2;
            }
            annCap = (casingDia ** 2 - pipeVol) / 1029.46; // bbl/ft
        }

        const annCapBblPerFt = annCap;
        const annCapGalPerFt = annCap * 42; // gal/ft
        const annCapFtPerGal = 1 / (annCap * 42); // ft/gal
        const annCapFtPerBbl = 1 / annCap; // ft/bbl
        const annCapFt3PerFt = annCap * 5.61; // ft3/ft
        const annCapFtPerFt3 = 1 / (annCap * 5.61); // ft/ft3

        // Calculate volumes
        const volBbl = annCap * totalLength;
        const volGal = annCapGalPerFt * totalLength;
        const volFt3 = annCapFtPerFt3 * totalLength;
        const volLiters = volBbl * 158.99;
        const volM3 = volBbl * 0.16;

        // Store results in the state
        setResults({
            annCapBblPerFt,
            annCapGalPerFt,
            annCapFtPerGal,
            annCapFtPerBbl,
            annCapFt3PerFt,
            annCapFtPerFt3,
            volBbl,
            volGal,
            volFt3,
            volLiters,
            volM3,
        });
    };

    return (
        <Container>
            <h2>Capacity Calculator</h2>
            <Form>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Hole Diameter ({unit})</Form.Label>
                            <Form.Control
                                type="number"
                                value={holeDiameter}
                                onChange={(e) => setHoleDiameter(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Casing OD ({unit})</Form.Label>
                            <Form.Control
                                type="number"
                                value={casingOD}
                                onChange={(e) => setCasingOD(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Casing ID ({unit})</Form.Label>
                            <Form.Control
                                type="number"
                                value={casingID}
                                onChange={(e) => setCasingID(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Total Length ({lengthUnit})</Form.Label>
                            <Form.Control
                                type="number"
                                value={length}
                                onChange={(e) => setLength(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Number of Strings</Form.Label>
                            <Form.Control
                                type="number"
                                value={numStrings}
                                onChange={(e) => setNumStrings(e.target.value)}
                                min="0"
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Diameter Unit</Form.Label>
                            <Form.Control
                                as="select"
                                value={unit}
                                onChange={(e) => setUnit(e.target.value)}
                            >
                                <option value="in">Inches</option>
                                <option value="cm">Centimeters</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Length Unit</Form.Label>
                            <Form.Control
                                as="select"
                                value={lengthUnit}
                                onChange={(e) => setLengthUnit(e.target.value)}
                            >
                                <option value="ft">Feet</option>
                                <option value="m">Meters</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Button variant="primary" onClick={calculateCapacities}>
                    Calculate
                </Button>
            </Form>

            {results && (
                <Table striped bordered hover className="mt-4">
                    <thead>
                        <tr>
                            <th>Unit</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr><td>gal/ft</td><td>{results.annCapGalPerFt.toFixed(4)}</td></tr>
                        <tr><td>ft/gal</td><td>{results.annCapFtPerGal.toFixed(4)}</td></tr>
                        <tr><td>bbl/ft</td><td>{results.annCapBblPerFt.toFixed(4)}</td></tr>
                        <tr><td>ft/bbl</td><td>{results.annCapFtPerBbl.toFixed(4)}</td></tr>
                        <tr><td>ft3/ft</td><td>{results.annCapFt3PerFt.toFixed(4)}</td></tr>
                        <tr><td>ft/ft3</td><td>{results.annCapFtPerFt3.toFixed(4)}</td></tr>
                        <tr><td>Volume (bbl)</td><td>{results.volBbl.toFixed(2)}</td></tr>
                        <tr><td>Volume (gal)</td><td>{results.volGal.toFixed(2)}</td></tr>
                        <tr><td>Volume (ft³)</td><td>{results.volFt3.toFixed(2)}</td></tr>
                        <tr><td>Volume (liters)</td><td>{results.volLiters.toFixed(2)}</td></tr>
                        <tr><td>Volume (m³)</td><td>{results.volM3.toFixed(2)}</td></tr>
                    </tbody>
                </Table>
            )}
        </Container>
    );
}

export default CapacityCalculator;
