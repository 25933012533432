import React from "react";
// import { Link } from "react-router-dom";
// import HoleSections from "../HoleSectionTable";
import RheologyTable from "./rheologyTable";
// import SidebarRight from "../SidebarRight";
import useToken from '../../admin/useToken'; // Import the custom hook


const RheologyHeader = ({ selectedCompany, selectedBlock, selectedField, selectedWell, selectedWellbore, selectedWellDesignCase, wellboreId, wellId, wellDesignCaseId }) => {
    const { token, setToken } = useToken(); // Use the custom hook to manage token state

    return (
        <div className="container">


            <div className="row">
                <div className="col">
                    <RheologyTable wellId={wellId} wellboreId={wellboreId} wellDesignCaseId={wellDesignCaseId} token={token} setToken={setToken} />
                </div>
            </div>
        </div>
    );
};

export default RheologyHeader;
