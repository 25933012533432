// AppContent.js
// This is a separate component that accesses useAuth and then passes its values down as props.
import React, { useState, useEffect } from 'react';
import { useAuth } from './components/admin/AuthContext';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import LandingPage from "./pages/LandingPage";
import RegisterPage from './pages/RegisterPage';
import PrivatePage from './pages/PrivatePage';
import AboutPage from './pages/AboutPage';
import Login from './components/admin/Login';
import Header from './pages/tabs_components/Header';
import Profile from './components/Profile';
import NotFound from './pages/NotFound';
import UnitConverterPage from './pages/UnitConverterPage';
import SurveyInterpolationPage from './pages/SurveyInterpolationPage';
import FluidCompressibilityPage from './pages/FluidCompressibilityPage';
import CasingDimensionsPage from './pages/CasingDimensionsPage';
import CapacityCalculationPage from './pages/CapacityCalculationPage';
import SessionManager from './SessionManager';
import HeaderPage from './pages/DesignCasePage';
import WellInformationPage from './pages/WellInformationPage';
import BhaTable from './components/well_information/bha_items/bhaTable';
import RheologyTable from './components/well_information/rheology/rheologyTable';
import PricingPage from './pages/PricingPage';
import ConfirmEmail from './components/admin/ConfirmEmail';
import SignupFeeback from './components/admin/SignupFeedback';
import SignupConfirmation from './components/admin/SignupConfirmation';
import PasswordResetForm from './components/admin/PasswordResetForm';
import TwoFactorSettings from './components/admin/TwoFactorSettings';
import TwoFactorVerification from './components/admin/TwoFactorVerification';
import TermsOfUse from './components/legal/TermsOfUse';
import PrivacyPolicy from './components/legal/PrivacyPolicy';
import CookiesPage from './components/legal/CookiesPage';

import AdminPanel from './admin';

// Lazy load components to improve performance
const HydraulicsPage = React.lazy(() => import('./pages/HydraulicsPage'));
const SwabAndSurgePage = React.lazy(() => import('./pages/SwabAndSurgePage'));
const KickTolerancePage = React.lazy(() => import('./pages/KickTolerancePage'));
const CasingDesignPage = React.lazy(() => import('./pages/CasingDesignPage'));

const AppContent = () => {
    const {
        isAuthenticated,
        setIsAuthenticated,
        isTwoFactorEnabled,
        setIsTwoFactorEnabled,
        isTwoFactorVerified,
        setIsTwoFactorVerified,
        isAdmin
    } = useAuth();


    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const [loading, setLoading] = useState(true);
    const [userId, setUserId] = useState(null);
    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState('');

    const location = useLocation();


    useEffect(() => {
        const verifyAuth = async () => {
            try {
                const response = await axios.get(`${baseURL}/verify_auth`, { withCredentials: true });
                setIsAuthenticated(response.data.authenticated);
                if (response.data.authenticated) {
                    setUserId(response.data.user_id);
                    setIsTwoFactorEnabled(response.data.two_factor_enabled);
                    setEmail(response.data.email);
                    setFullName(response.data.full_name);

                    // Set isTwoFactorVerified based on 2FA status
                    if (!response.data.two_factor_enabled) {
                        setIsTwoFactorVerified(true); // Mark as verified if 2FA is not enabled
                    }
                }
            } catch (error) {
                setIsAuthenticated(false);
            } finally {
                setLoading(false); // Set loading to false once the check completes
            }
        };
        verifyAuth();
    }, [baseURL, setIsAuthenticated, setIsTwoFactorEnabled, setIsTwoFactorVerified]);


    if (loading) {
        return <div>Loading...</div>; // Loading screen while verifying authentication status
    }

    // console.log('isAuthenticated', isAuthenticated)
    // console.log('isTwoFactorEnabled', isTwoFactorEnabled)

    return (
        <>
            {/* <Header isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} email={email} isTwoFactorVerified={isTwoFactorVerified} setIsTwoFactorVerified={setIsTwoFactorVerified} fullName={fullName} /> */}

            {/* Render Header only when not on an /admin route */}
            {!location.pathname.startsWith('/admin') && (
                <Header
                    isAuthenticated={isAuthenticated}
                    setIsAuthenticated={setIsAuthenticated}
                    isTwoFactorVerified={isTwoFactorVerified}
                    setIsTwoFactorVerified={setIsTwoFactorVerified}
                    email={email} 
                    fullName={fullName}
                />
            )}
            {isAuthenticated && <SessionManager />}

                                        <Routes>
                                            {/* Public Routes */}
                                            <Route path="/" element={<LandingPage />} />
                                            <Route path="/about" element={<AboutPage />} />
                                            <Route path="/pricing" element={<PricingPage />} />
                                            <Route path="/register" element={<RegisterPage />} />
                                            <Route path="/signup_feedback" element={<SignupFeeback />} />
                                            <Route path="/signup_confirmation" element={<SignupConfirmation />} />
                                            <Route path="/reset_password/:token" element={<PasswordResetForm />} />
                                            <Route path="/confirm/:token" element={<ConfirmEmail />} />
                                            <Route path="/terms-of-use" element={<TermsOfUse />} />
                                            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                                            <Route path="/cookies" element={<CookiesPage />} />
                                            <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} setIsTwoFactorVerified={setIsTwoFactorVerified} />} />

                                            {/* 2FA Verification Route */}
                                            <Route
                                                path="/2fa_verification"
                                                element={
                                                    isAuthenticated && isTwoFactorEnabled && !isTwoFactorVerified ? (
                                                        <TwoFactorVerification user_id={userId} setIsTwoFactorVerified={setIsTwoFactorVerified} />
                                                    ) : (
                                                        <Navigate to="/" />
                                                    )
                                                }
                                            />

                                            {/* Protected Routes */}
                                            {isAuthenticated && (isTwoFactorVerified || !isTwoFactorEnabled) ? (
                                                <>
                                                    <Route path="/design_case" element={<HeaderPage isTwoFactorVerified={isTwoFactorVerified} />} />
                                                    <Route path="/units" element={<UnitConverterPage />} />
                                                    <Route path="/interpolation" element={<SurveyInterpolationPage />} />
                                                    <Route path="/compressibility" element={<FluidCompressibilityPage />} />
                                                    <Route path="/casing_dimensions_strength" element={<CasingDimensionsPage />} />
                                                    <Route path="/capacities" element={<CapacityCalculationPage />} />
                                                    <Route path="/bha_table" element={<BhaTable />} />
                                                    <Route path="/rheology_table" element={<RheologyTable />} />
                                                    <Route path="/hydraulics" element={<HydraulicsPage />} />
                                                    <Route path="/swab_and_surge" element={<SwabAndSurgePage />} />
                                                    <Route path="/kick_tolerance" element={<KickTolerancePage />} />
                                                    <Route path="/casing_design" element={<CasingDesignPage />} />
                                                    <Route path="/well_information" element={<WellInformationPage />} />
                                                    <Route path="/private" element={<PrivatePage />} />
                                                    <Route path="/profile" element={<Profile />} />
                                                    <Route path="/2fa_settings" element={<TwoFactorSettings />} />
                                                </>
                                            ) : (
                                                <Route path="*" element={<Navigate to="/login" setIsAuthenticated={setIsAuthenticated} setIsTwoFactorVerified={setIsTwoFactorVerified} />} />
                                            )}

                                            {/* 404 Route */}
                                            <Route path="*" element={<NotFound />} />
                                        
                {/* Admin routes */}
                {isAuthenticated && isAdmin && (
                    
                    <Route path="/admin/*" element={<AdminPanel />} />

                )}
                
            </Routes>

        </>
    );
};

export default AppContent;
