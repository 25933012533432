// import { useState } from 'react';

// function useToken() {

//     function getToken() {
//         const userToken = localStorage.getItem('token'); //https://javascript.info/localstorage
//         return userToken && userToken
//     }

//     const [token, setToken] = useState(getToken());

//     function saveToken(userToken) {
//         localStorage.setItem('token', userToken);
//         setToken(userToken);
//     }

//     function removeToken() {
//         localStorage.removeItem("token");
//         setToken(null);
//     }

//     return {
//         setToken: saveToken,
//         token,
//         removeToken
//     }

// }

// export default useToken;


// useToken.js
import { useState } from 'react';
import Cookies from 'js-cookie';

export default function useToken() {
    // Retrieve the token from cookies
    const getToken = () => {
        return Cookies.get('access_token');
    };

    const [token, setTokenState] = useState(getToken());

    const setToken = (userToken) => {
        Cookies.set('access_token', userToken, { expires: 7 }); // Store token for 7 days
        setTokenState(userToken);
    };

    const removeToken = () => {
        Cookies.remove('access_token'); // Corrected the cookie name to 'access_token'
        setTokenState(null);
    };


    return {
        token,
        setToken,
        removeToken,
    };
}
