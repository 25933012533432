import { Create, SimpleForm, TextInput } from 'react-admin';

const UserCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput source="full_name" />
            <TextInput source="email" />
            <TextInput source="job_title" />
        </SimpleForm>
    </Create>
);

export default UserCreate;
